/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateQueuePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createQueue, updateQueue, getQueueFilters } from '../../../actions/Collider/ClusterAction'
import { Spinner } from 'reactstrap';
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection';
 import { Store as CommonNotification } from 'react-notifications-component';
import { momentSlashConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, alphaNumeric, capitalizeAllLetter, getAccountNameFromId, getRegionName, onlyNumeric } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const CreateUpdateQueuePanel = ({ closeSidePanel, pageMode, selectedQueueDetails }) => {

    const clickOut = useRef();

    const [state, setState] = useState({
        selectedAccount: '',
        selectedRegion: '',
        // tagValues: ['aaaaaaaaaaaaa', 'bbbbbbbbbbbbbbbbbb'],
        queueArray: [0],
        computeArray_0: [0],
        s3AccessArray_0: [0],        
        clusters: [],
        instanceTypes: [],
        emptyOptions: [],
        selectedProvider: pageMode !== 'Create' ? selectedQueueDetails.provider : '',
        subnetDropdown: false,
    })

    // const allStates = useSelector(state => state)
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const tagKeys = useSelector(state => state.filters.tagKeys);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true,
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: true
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider            
            if(state.selectedProjectTags) {
                label = ''
                params.project_tags = state.selectedProjectTags
            }
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label !== '') {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]); 

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider            
            if(state.selectedAccount) {
                label = ''
                params.account_id = state.selectedAccount
            }
            if(state.selectedProject) {
                label = '_'
                params.project_tags = state.selectedProjectTags
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            if(label !== '') {
                                let obj = propProjRegions ? propProjRegions : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjRegions', obj))
                            }
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);
    
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    useEffect(() => {
        if(!tagKeys || !tagKeys.length) {
			let params = {}
			// params.account_id = '265469181489'
			// params.region = 'us-east-2'
			dispatch(getAllTagsKeys(params))
				.then((response) => {
                    if(response) {
					    // setState(prevState => ({ ...prevState, showTagKeyLoading: !state.showTagKeyLoading }))
                    }
				})
		}        
    }, [dispatch, tagKeys])

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            // selectedCluster: "",
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        let params= {}
        dispatch(getQueueFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ 
                        ...prevState,
                        securityGroupIdOptions: response.security_group_ids ? response.security_group_ids : [],
                        subnetIdOptions: response.subnet_ids ? response.subnet_ids : [],
                        policiesOptions: response.policies ? response.policies : [],
                        instanceTypes: response.instance_type ? response.instance_type : [],
                        instanceTypeOptions: response.update_strategy ? response.update_strategy : [],
                        updateStrategies: response.update_strategy ? response.update_strategy : [],
                        volumeTypes: response.volume_type ? response.volume_type : [],
                    }))
                }
            })
    }, [dispatch])

    useEffect(() => {
        if(pageMode !== 'Create') {
            let queueConfiguration = selectedQueueDetails.queue_configuration ? selectedQueueDetails.queue_configuration : []

            let queueArray = []
            let computeArray = []
            let s3AccessArray = []
            if(!Array.isArray(queueConfiguration)) {
                queueConfiguration = [queueConfiguration]
            }
            queueConfiguration.forEach((row, index) => {
                queueArray.push(index)
                setState(prevState => ({
                    ...prevState,
                    ['queue_name_'+index]: row.queue_name ? row.queue_name : '',
                    ['volume_type_'+index]: row.volume_type ? row.volume_type : '',
                    ['volume_size_'+index]: row.volume_size ? row.volume_size : '',
                    ['volume_encryption_'+index]: row.volume_encryption ? true : false,
                    ['subnet_ids_'+index]: row.subnet_ids ? row.subnet_ids : [],
                    ['security_group_ids_'+index]: row.security_group_ids ? row.security_group_ids : [],
                    ['policies_'+index]: row.policies ? row.policies : [],
                    ['selected_tags_'+index]: row.tags ? row.tags : [],
                    ['update_strategy_'+index]: row.update_strategy ? row.update_strategy : '',
                    ['on_node_configured_script_'+index]: row.on_node_configured_script ? row.on_node_configured_script : '',
                    ['showNodeScript_'+index]: row.on_node_configured_script ? true : false
                }))

                row.compute_resources && row.compute_resources.forEach((com, cIndex) => {
                    computeArray.push(cIndex)
                    setState(prevState => ({
                        ...prevState,
                        ['compute_resource_name_'+index+'_'+cIndex]: com.compute_resource_name ? com.compute_resource_name : '',
                        ['instance_type_'+index+'_'+cIndex]: com.instance_type ? com.instance_type : '',
                        ['max_instance_count_'+index+'_'+cIndex]: com.max_instance_count ? com.max_instance_count : 0,
                        ['min_instance_count_'+index+'_'+cIndex]: com.efa_enabled ? com.efa_enabled : 0,
                        ['efa_enabled_'+index+'_'+cIndex]: com.efa_enabled ? true : false,
                        ['placement_group_enabled_'+index+'_'+cIndex]: com.placement_group_enabled ? true : false,
                        ['disable_simultaneous_multithreading_'+index+'_'+cIndex]: com.disable_simultaneous_multithreading ? true : false,
                    }))
                })

                s3AccessArray.push(index)
                if(row.s3_access && row.s3_access.length) {
                    if(row.s3_access && row.s3_access.length) {
                        s3AccessArray = []
                        row.s3_access.forEach((row, sIndex) => {
                            s3AccessArray.push(sIndex)
                            setState(prevState => ({
                                ...prevState,
                                ['bucket_name_'+index+'_'+sIndex]: row.bucket_name ? row.bucket_name : '',
                                ['enable_write_access_'+index+'_'+sIndex]: row.enable_write_access ? true : false,
                            }))
                        })
                    }
                }

                setState(prevState => ({
                    ...prevState,
                    ['computeArray_'+index]: computeArray,
                    ['s3AccessArray_'+index]: s3AccessArray,
    
                }))
            })

            setState(prevState => ({
                ...prevState,
                selectedProvider: selectedQueueDetails.provider ? selectedQueueDetails.provider : '',
                selectedAccount: selectedQueueDetails.account_id ? selectedQueueDetails.account_id : '',
                selectedRegion: selectedQueueDetails.region ? selectedQueueDetails.region : '',
                selectedCluster: selectedQueueDetails.cluster_name ? selectedQueueDetails.cluster_name : '',                
                scheduled_at: selectedQueueDetails.scheduled_at ?  momentConvertionUtcToLocalTime(selectedQueueDetails.scheduled_at, "DD/MM/YYYY HH:mm")  : '',
                queueArray,

            }))
        }
    }, [pageMode, selectedQueueDetails])

    useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ 'key': state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
                    if(response) {
					    setState(prevState => ({ ...prevState, tagValues: response }))
                    }
				})
		}
	}, [state.tag_key, dispatch])

    // const getAllTagsValuesFunction = (key, index) => {
    //     setState(prevState => ({ ...prevState, ['tag_key_'+index]: key, ['tagValues_'+index]: [], }))
    //     let params = {}
    //     params.tags = [{ 'key': key }]
    //     dispatch(getAllTagsValues(params))
    //         .then((response) => {
    //             if(response) {
    //                 setState(prevState => ({ ...prevState, ["tagValues_"+index]: response }))
    //             }
    //         })
    // }
	
	const handleInputChange = (index, label, value) => {
        if(index !== '') {
            setState(prevState => ({ ...prevState, [label+'_'+index]: value }))
        } else {
            setState(prevState => ({ ...prevState, [label]: value }))
        }
	}

    const handleMultiInputChange = (index, label, value) => {
        setState(prevState => ({ ...prevState, [label+'_'+index]: value }))
	}

    const addDetails = (index) => {
        let policies = state['policies_'+index] ? state['policies_'+index] : []
        if(state['input_policy_'+index]) {
            policies.push(state['input_policy_'+index])
        }
        
        setState(prevState => ({ ...prevState, ['policies_'+index]: policies, ['input_policy_'+index]: "" }))
    }

    const handleInnerInputChange = (index, cIndex, label, value) => {
        let computerResourceNameAlreadyExist = false
        if(label === 'compute_resource_name' && state['computeArray_'+index].length) {
            if(state['computeArray_'+index].filter(e => e.compute_resource_name === value).length > 1) {
                computerResourceNameAlreadyExist = true
            }
        }

		setState(prevState => ({ ...prevState, [label+'_'+index+'_'+cIndex]: value, computerResourceNameAlreadyExist }))
	}

	const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateDateTime(str, state)
		} else {
			validateDateTime(time, state)
		}
	}

    const validateDateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let date = str.substring(0, 2)

        let scheduleAt = ''
        if(strLength > 1 && parseInt(date) > 31) {
			scheduleAt += '01/'
		} else if(strLength > 1 && parseInt(date) < 10) {
			if(date.length === 2) {
				scheduleAt = date+'/'
			} else {
				scheduleAt += '0'+date+'/'
			}
		} else if(strLength > 1 && parseInt(date) <= 31) {
			scheduleAt += date+'/'
		}

		if(scheduleAt === '') {
			scheduleAt = dateTime
		}
		
		let month = str.substring(2, 4)
		
		if(month.length) {
			if(strLength > 3 && parseInt(month) > 12) {
				scheduleAt += '01/'
			} else if(strLength > 3 && parseInt(month) > 10) {
				scheduleAt += month+'/'
			} else if(strLength > 3 && month.length === 2) {
				scheduleAt += month+'/'
			} else {
                scheduleAt += month
            }
		}
		
        let year = str.substring(4, 8)
		if(strLength > 4 && year.length < 4) {
            scheduleAt += year
		} else if(strLength > 5 && year.length === 4) {
            scheduleAt += year+' '
        }

		let firstHour = str.substring(8, 10)

		if(strLength > 8 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 9 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 8 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } 
		
		let firstMinute = str.substring(10, 12)
		
		if(firstMinute.length) {
			if(strLength > 10 && parseInt(firstMinute) > 59) {
				scheduleAt += '00'
			} else if(strLength > 10 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    // const formatYamlString = (data, indentLevel = 0) => {
    //     const spaces = ' '.repeat(indentLevel * 2);
    //     let formattedString = '';
    
    //     for (const key in data) {
    //         if (data.hasOwnProperty(key)) {
    //             const value = data[key];
    //             if (typeof value === 'object') {
    //                 if (Array.isArray(value)) {
    //                     formattedString += `${spaces}${key}:\n${formatYamlString(value, indentLevel + 1)}`;
    //                 } else {
    //                     formattedString += `${spaces}${key}:\n${formatYamlString(value, indentLevel + 1)}`;
    //                 }
    //             } else {
    //                 formattedString += `${spaces}${key}: ${value}\n`;
    //             }
    //         }
    //     }
    
    //     return formattedString.trim().replace(/\n/g, '\\n');
    // }    

    // const handleJsYamlConvertsion = (event) => {
    //     let value = event.target.value
    //     try {
    //         const yamlConfig = yaml.load(value);
    //         if (yamlConfig === null || typeof yamlConfig !== 'object') {
    //             throw new Error('Invalid YAML format');
    //         }
    
    //         const formattedYamlString = formatYamlString(yamlConfig);

    //         setState(prevState => ({ ...prevState, formattedYamlString }))
    
    //     } catch (error) {
    //         if (error.message.includes('unexpected end of the stream within a double quoted scalar')) {
    //             // console.error('YAML parsing error: Unexpected end of the stream within a double quoted scalar');
    //         } else {
    //             // console.error('Error converting YAML:', error.message);
    //         }
    //     }

    //     setState(prevState => ({ ...prevState, description: value }))
    
    //     // const yamlConfig = yaml.load(value);
    //     // const formattedYamlString = formatYamlString(yamlConfig);
    
    //     // const yamlConfig = yaml.dump(JSON.parse(value));
    // }

	// const addtoList = (textField, listField, index) => {
	// 	// let sharedDetails = state['shared_details_'+item] ? state['shared_details_'+item] : []
    //     // if(state['shared_to_'+item] && state['shared_to_'+item] !== '') {
	// 	// 	sharedDetails.push(state['shared_to_'+item])
	// 	// }

    //     let listItems = state[listField+'_'+index] ? state[listField+'_'+index] : []
    //     if(state[textField+'_'+index]) {
    //         listItems.push(state[textField+'_'+index])
    //     }

	// 	setState(prevState => ({ ...prevState, [listField+'_'+index]: listItems, [textField+'_'+index]: '' }))
    // }

	const removefromList = (index, value, listField) => {
		// let filteredResult = state['shared_details_'+item].filter(e => e !== sharedTo)
        // setState({ ['shared_details_'+item]: filteredResult })

        let filteredListItem = state[listField+'_'+index].filter(e => e !== value)

        let array = []
        filteredListItem.forEach(row => {
            let rowValue = {
                value: row,
                label: row
            }
            array.push(rowValue)
        })

        setState(prevState => ({ ...prevState, [listField+'_'+index]: filteredListItem }))
    }

    // const addNewTag = (index) => {
	// 	let selectedTags = state['selected_tags_'+index] ? state['selected_tags_'+index] : []

	// 	if(state['tag_key_'+index] && state['tag_key_'+index] !== '' && state['tag_value_'+index] && state['tag_value_'+index].length) {
	// 		state['tag_value_'+index].forEach(tag => {
	// 			if(!selectedTags.filter(e => e.key === state['tag_key_'+index] && e.value === tag).length) {
	// 				let dataRow = {}
	// 				dataRow.key = state['tag_key_'+index]
	// 				dataRow.value = tag
	// 				selectedTags.push(dataRow)
	// 			}
	// 		})
	// 	}

	// 	setState(prevState => ({ ...prevState, ['selected_tags_'+index]: selectedTags, ['tag_key_'+index]: '',  ['tag_value_'+index]: [], ['tagValues_'+index]: [] }))
	// }

	const removeTagSection = (tag, index) => {
		let filteredResult = state['selected_tags_'+index] && state['selected_tags_'+index].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, ['selected_tags_'+index]: filteredResult }))
	}

    const addSection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }

    const addSectionQuerySection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);

            setState(prevState => ({ ...prevState, [array]: rowList, ['computeArray_'+value]: [0], ['s3AccessArray_'+value]: [0] }))
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }

    const removeSection = (array, index, i) => {
        setState(prevState => ({ ...prevState, ['queue_name_'+index]: '', ['volume_type_'+index]: '', ['subnet_ids_'+index]: [], ['security_group_ids_'+index]: [], ['policies_'+index]: [], ['selected_tags_'+index]: []}))

        let rowList = state[array];
        rowList.splice(i, 1);
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }

    const removeCRSection = (array, index, cIndex, j) => {
        if(array === 's3AccessArray') {
            setState(prevState => ({ ...prevState, ['bucket_name_'+index+'_'+cIndex]: '', ['instance_type_'+index+'_'+cIndex]: '' }))
        } else {
            setState(prevState => ({ ...prevState, ['max_instance_count_'+index+'_'+cIndex]: '', ['enable_write_access_'+index+'_'+cIndex]: false }))
        }

        let rowList = state[array+'_'+index];
        rowList.splice(j, 1);
        setState(prevState => ({ ...prevState, [array+'_'+index]: rowList }))
    }

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.project_tags = state.selectedProjectTags ? state.selectedProjectTags : []
            params.force_update = state.force_update

            let inputValidationError = false
            if(!state.selectedProvider || !state.selectedProject || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster) {
                inputValidationError = true
            }
            if(state['scheduled_at']) {
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state['scheduled_at'], "YYYY-MM-DD HH:mm:00")
            }

            let queueConfiguration = []

            let queueArray = state.queueArray
            queueArray.forEach((index, i) => {
                let rowData = {}            
                rowData.queue_name = state['queue_name_'+index]
                rowData.update_strategy = state['update_strategy_'+index]
                rowData.volume_type = state['volume_type_'+index]
                rowData.volume_size = state['volume_size_'+index]
                rowData.volume_encryption = state['volume_encryption_'+index]                
                rowData.subnet_ids = state['subnet_ids_'+index]
                rowData.security_group_ids = state['security_group_ids_'+index]
                rowData.policies = state['policies_'+index]
                rowData.tags = state['selected_tags_'+index] ? state['selected_tags_'+index] : []
                rowData.on_node_configured_script = state['showNodeScript_'+index] && state['on_node_configured_script_'+index] ? state['on_node_configured_script_'+index] : ''

                if(!rowData.queue_name || !rowData.subnet_ids || !rowData.subnet_ids.length || !rowData.update_strategy) {
                    inputValidationError = true
                }

                let computeResources = []
                state['computeArray_'+index].forEach((cIndex, j) => {
                    if(!state['max_instance_count_'+index+'_'+cIndex]) {
                        inputValidationError = true
                    }
                    let comRow = {
                        compute_resource_name: state['compute_resource_name_'+index+'_'+cIndex],
                        instance_type: state['instance_type_'+index+'_'+cIndex],
                        min_instance_count: state['min_instance_count_'+index+'_'+cIndex] ? Math.round(parseFloat(state['min_instance_count_'+index+'_'+cIndex])) : 0,
                        max_instance_count: state['max_instance_count_'+index+'_'+cIndex] ? Math.round(parseFloat(state['max_instance_count_'+index+'_'+cIndex])) : 0,
                        efa_enabled: state['efa_enabled_'+index+'_'+cIndex] ? true : false,
                        placement_group_enabled: state['placement_group_enabled_'+index+'_'+cIndex] ? true : false,
                        disable_simultaneous_multithreading: state['disable_simultaneous_multithreading_'+index+'_'+cIndex] ? true : false,
                    }
                    if(Object.entries(comRow).length) {
                        computeResources.push(comRow)
                    }
                })
                rowData.compute_resources = computeResources

                let s3Access = []
                state['s3AccessArray_'+index].forEach(sIndex => {
                    let comRow = {
                        bucket_name: state['bucket_name_'+index+'_'+sIndex],
                        enable_write_access: state['enable_write_access_'+index+'_'+sIndex] ? true : false
                    }
                    if(state['bucket_name_'+index+'_'+sIndex]) {
                        s3Access.push(comRow)
                    }

                })
                if(!s3Access.length) {
                    inputValidationError = true
                }
                rowData.s3_access = s3Access

                queueConfiguration.push(rowData)
            })
            params.queue_configuration = queueConfiguration

            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : false }))
            if(!inputValidationError) {
                if(pageMode !== 'Edit') {
                    dispatch(createQueue(params))
                        .then((response) => {
                            let messageType = ''
                            let message = ''
                            let createErrorMessage = ''
                            if(response) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = 'Queue Created Successfully'
                                } else {
                                    messageType = 'danger'
                                    message = 'Error in saving Queue'
                                    createErrorMessage = response.message
            
                                }
                            } else {
                                messageType = 'danger'
                                message = 'Error in saving Queue'
                            }
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
                            }
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateQueue(params))
                        .then((response) => {
                            let messageType = ''
                            let message = ''
                            let createErrorMessage = ''
                            if(response) {
                                if(response.status) {
                                    messageType = 'success'
                                    message = 'Queue Updated Successfullt'
                                } else {
                                    messageType = 'danger'
                                    message = 'Error in updating Queue'
                                    createErrorMessage = response.message
            
                                }
                            } else {
                                messageType = 'danger'
                                message = 'Error in updating Queue'
                            }
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
                            }
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                }

            }
        }
    }, [state.callSaveFunction, state, closeSidePanel, dispatch, pageMode])

    const addNewTagValue = (index) => {
		let selectedTags = state['selected_tags_'+index] ? state['selected_tags_'+index] : []

		if(state['tag_key_'+index] && state['tag_key_'+index] !== '' && state['tag_value_'+index] && state['tag_value_'+index].length) {
            let dataRow = {}
            dataRow.key = state['tag_key_'+index]
            dataRow.value = state['tag_value_'+index]
            selectedTags.push(dataRow)
		}

		setState(prevState => ({ ...prevState, ['selected_tags_'+index]: selectedTags, ['tag_key_'+index]: '',  ['tag_value_'+index]: '' }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search h-full' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='w-3/5 bg-nero overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <p className='text-white text-xl'>{pageMode} Queue</p>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded-md bg-dark3 p-3'>
                        {state.createErrorMessage ?
                            <p className='text-red mb-2 text-center f18'>Error in saving data</p>
                        : null}
                        <div className='flex flex-wrap'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Provider</p>
                                        {state.inputValidationError && !state.selectedProvider ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isProviderOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedProvider ? 'text-white' : 'text-lightGray'}`}>
                                            {state.selectedProvider ? capitalizeAllLetter(state.selectedProvider) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isProviderOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["provider_name", "provider_name"]}
                                                    options={state.providers}
                                                    selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: "", selectedProject: "", selectedProjectTags: [], selectedRegion: "", selectedCluster: "" }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Project</p>
                                        {state.inputValidationError && !state.selectedProject ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isProjectOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isProjectOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedProject ? 'text-white' : 'text-lightGray'}`}>
                                            {state.selectedProject ? capitalizeAllLetter(state.selectedProject) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedProject ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isProjectOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["project", "project"]}
                                                    options={state.projects}
                                                    selectedValues={state.selectedProject ? state.selectedProject : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, selectedProject: value, selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : [] }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Account</p>
                                        {state.inputValidationError && !state.selectedAccount ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isAccountOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedAccount ? 'text-white' : 'text-lightGray'}`}>
                                            {state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isAccountOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["account_id", "account_name"]}
                                                    options={state.accounts}
                                                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState,  selectedAccount: value, selectedCluster: "" }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Region</p>
                                        {state.inputValidationError && !state.selectedRegion ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isRegionOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedRegion ? 'text-white' : 'text-lightGray'}`}>
                                            {state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isRegionOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["region", "name"]}
                                                    options={state.regions}
                                                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, 
                                                                selectedRegion: value, selectedCluster: ""
                                                            }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0">Cluster Name</p>
                                        {state.inputValidationError && !state.selectedCluster ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <div onClick={(event) => {
                                        if(!state.isClusterOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
                                        }
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state.selectedCluster ? 'text-white' : 'text-lightGray'}`}>
                                            {state.selectedCluster ? state.selectedCluster : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedCluster ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isClusterOpen && pageMode === 'Create' ?
                                            <div className='relative'>
                                                <MultiSelectSection
                                                    options={state.clusters}
                                                    selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, selectedCluster: value }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <div className='lg:w-1/3 md:w-2/3 w-full'>
                                    <p className='b-block mb-0'>Schedule At</p>
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='DD/MM/YYY HH:mm'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            // maxlength='128'
                                            value={state.scheduled_at ? state.scheduled_at : ''}
                                            // onChange={e => handleInputChange(index, 'scheduled_at', e.target.value)}
                                            onKeyDown={e => onKeyDownTimeRange(e, e.target.value, 'scheduled_at')}
                                            onChange={e => validateDateTime(e.target.value, 'scheduled_at')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <p className='b-block my-2 text-cyon f18'>Queue Configurations</p>
                    
                    {state.queueArray && state.queueArray.map((index, i) => {
                        return(
                            <div key={'q_'+index} className='rounded-md bg-dark3 p-3 mb-2'>
                                <div className='flex flex-wrap mt-2'>
                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                        <p className='b-block mb-0 flex'>
                                            Queue Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.inputValidationError && !state['queue_name_'+index] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        {/* isDisabled={pageMode === 'Edit' ? true : false} */}
                                        {pageMode === 'View' ?
                                            <p className='mb-0 text-white'>{state['queue_name_'+index] ? state['queue_name_'+index] : ''}</p>
                                        : 
                                            <input
                                                type='text'
                                                placeholder='Enter queue name'
                                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                // maxlength='128'
                                                value={state['queue_name_'+index] ? state['queue_name_'+index] : ''}
                                                onChange={e => handleInputChange(index, 'queue_name', e.target.value)}
                                            />
                                        }
                                    </div>
                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                        <p className='b-block mb-0 flex'>
                                            Scaledown idle time (minutes)
                                            {state.inputValidationError && !state['scaledown_idle_time_'+index] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <input
                                            type='text'
                                            placeholder='Enter time'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            // maxlength='128'
                                            value={state['scaledown_idle_time_'+index] ? state['scaledown_idle_time_'+index] : ''}
                                            onChange={e => handleInputChange(index, 'scaledown_idle_time', e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-wrap mt-2'>
                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                        <div onClick={(event) => {
                                            if(!state['showQueueUpdateStartegy_'+index]) {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', 'showQueueUpdateStartegy_'+index)
                                            }
                                        }}>
                                            <p className='b-block mb-0 flex truncate'>Queue update strategy <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" /></p>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['update_strategy_'+index] ? 'text-white' : 'text-lightGray'}`}>
                                                {state['update_strategy_'+index] ? state['update_strategy_'+index] : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state['update_strategy_'+index] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state['showQueueUpdateStartegy_'+index] ?
                                                <div className='relative'>
                                                <MultiSelectSection
                                                    options={state.updateStrategies ? state.updateStrategies : []}
                                                    selectedValues={state['update_strategy_'+index] ? state['update_strategy_'+index] : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(!value || typeof(value) === 'string') {
                                                            setState(prevState => ({ ...prevState, ['update_strategy_'+index]: value }))
                                                        } else {
                                                            value.preventDefault()
                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0' onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', "showSubnetDropdown_"+index)
                                    }}>
                                        <p className='b-block mb-0 flex'>
                                            Subnet IDs <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                            {state.inputValidationError && (!state['subnet_ids_'+index] || !state['subnet_ids_'+index].length) ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['subnet_ids_'+index] && state['subnet_ids_'+index].length ? 'text-white' : 'text-lightGray'}`}>
                                            {state['subnet_ids_'+index] && state['subnet_ids_'+index].length ? state['subnet_ids_'+index].length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['subnet_ids_'+index] && state['subnet_ids_'+index].length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["showSubnetDropdown_"+index] && pageMode !== 'View' ?
                                            <div className='relative'>
                                                <MultiSelectSection
                                                    options={state.subnetIdOptions}
                                                    selectedValues={state['subnet_ids_'+index] ? state['subnet_ids_'+index] : []}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ['subnet_ids_'+index]: value }))}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                    className={'lg:right-auto right-0'}
                                                />
                                            </div>
                                        : null}
                                        {state['subnet_ids_'+index] && state['subnet_ids_'+index].length ? 
                                            <div className='flex flex-wrap'>
                                                {state['subnet_ids_'+index].map(row => {
                                                    return(
                                                        <span key={'subnet_'+index+'_'+row} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center text-info px-2'>{row}
                                                            <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-white ml-2 self-center cursor-pointer' onClick={ () => removefromList(index, row, 'subnet_ids') } />
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <div className='flex flex-wrap mt-2'>
                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full' onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', "showSecurityGraoupDropdown_"+index)
                                    }}>
                                        <p className='b-block mb-0'>Security Group IDs</p>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['security_group_ids_'+index] && state['security_group_ids_'+index].length ? 'text-white' : 'text-lightGray'}`}>{state['security_group_ids_'+index] && state['security_group_ids_'+index].length ? state['security_group_ids_'+index].length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state['security_group_ids_'+index] && state['security_group_ids_'+index].length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["showSecurityGraoupDropdown_"+index] && pageMode !== 'View' ?
                                            <div className='relative'>
                                            <MultiSelectSection
                                                options={state.securityGroupIdOptions}
                                                selectedValues={state['security_group_ids_'+index] ? state['security_group_ids_'+index] : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ['security_group_ids_'+index]: value }))}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                            </div>
                                        : null}
                                        {state['security_group_ids_'+index] && state['security_group_ids_'+index].length ? 
                                            <div className='flex flex-wrap'>
                                                {state['security_group_ids_'+index].map(row => {
                                                    return(
                                                        <span key={'security_'+index+'_'+row} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center f14 text-info px-2'>{row}
                                                            <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-white ml-2 self-center cursor-pointer' onClick={ () => removefromList(index, row, 'security_group_ids') } />
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        : null}
                                    </div>

                                    <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                        <p className='b-block mb-0 flex'>
                                            Reservation ID
                                            {state.inputValidationError && !state['reservation_id_'+index] ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <input
                                            type='text'
                                            placeholder='Enter capacity reservation Id'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            // maxlength='128'
                                            value={state['reservation_id_'+index] ? state['reservation_id_'+index] : ''}
                                            onChange={e => handleInputChange(index, 'reservation_id', e.target.value)}
                                        />
                                    </div>
                                </div>

                                <p className='b-block text-white my-2'>Compute Resources</p>
                                <div className='rounded-md bg-dark p-3 mb-3'>
                                    {state['computeArray_'+index] && state['computeArray_'+index].map((cIndex, j) => {
                                        return(
                                            <div key={'compArr_'+index+'_'+cIndex+'_'+j} className='rounded-md bg-dark2 p-3 mb-1'>
                                                {state['computeArray_'+index].map(row => {
                                                    return(
                                                    (row !== cIndex && state['compute_resource_name_'+index+'_'+cIndex] && state['compute_resource_name_'+index+'_'+cIndex] === state['compute_resource_name_'+index+'_'+row]) ?
                                                        <p key={'dup_'+index+'_'+cIndex+'_'+j} className='mb-1 text-danger'>
                                                            Duplicate Name
                                                        </p>
                                                    : null
                                                    )
                                                })}
                                                <div className='flex flex-wrap mt-2'>
                                                    <div className='lg:w-4/5 w-full'>
                                                        <div className='flex flex-wrap'>
                                                            <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                                                <p className='b-block mb-0 flex'>
                                                                    Name <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                                    {state.inputValidationError && (!state['compute_resource_name_'+index+'_'+cIndex] || !state['compute_resource_name_'+index+'_'+cIndex].length) ?
                                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                                    : null}
                                                                </p>
                                                                {pageMode === 'View' ?
                                                                    <p className='mb-0 text-white'>{state['compute_resource_name_'+index+'_'+cIndex] ? state['compute_resource_name_'+index+'_'+cIndex] : ''}</p>
                                                                : 
                                                                    <div className='flex'>
                                                                        <input
                                                                            type='text'
                                                                            id={'compute_resource_name_'+index+'_'+cIndex}
                                                                            placeholder='Enter compute resource name'
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                                            // maxlength='128'
                                                                            value={state['compute_resource_name_'+index+'_'+cIndex] ? state['compute_resource_name_'+index+'_'+cIndex] : ''}
                                                                            onChange={e => handleInnerInputChange(index, cIndex, 'compute_resource_name', alphaNumeric(e.target.value))}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0' onClick={(event) => {
                                                                if(!state['showInstanceTypes_'+index+'_'+cIndex]) {
                                                                    event.preventDefault();
                                                                    handleChildClick(event, 'child', 'singleDropDown', 'showInstanceTypes_'+index+'_'+cIndex)
                                                                }
                                                            }}>
                                                                <p className='b-block mb-0 flex truncate'>
                                                                    Instance&nbsp;Type <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                                    {/* {state.inputValidationError && (!state['instance_type_'+index+'_'+cIndex] || !state['instance_type_'+index+'_'+cIndex].length) ?
                                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                                    : null} */}
                                                                </p>
                                                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['instance_type_'+index+'_'+cIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                                    {state['instance_type_'+index+'_'+cIndex] ? state['instance_type_'+index+'_'+cIndex] : 'Select'}
                                                                    <Icon icon="icon-park-solid:down-one" className={`${state['instance_type_'+index+'_'+cIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                </p>
                                                                {state['showInstanceTypes_'+index+'_'+cIndex] && pageMode !== 'View' ?
                                                                    <div className='relative'>
                                                                    <MultiSelectSection
                                                                        options={state.instanceTypes}
                                                                        selectedValues={state['instance_type_'+index+'_'+cIndex] ? state['instance_type_'+index+'_'+cIndex] : []}
                                                                        callbackMultiSelect={(value) => {
                                                                            if(!value || typeof(value) === 'string') {
                                                                                setState(prevState => ({ ...prevState, ['instance_type_'+index+'_'+cIndex]: value }))
                                                                            } else {
                                                                                value.preventDefault()
                                                                                handleChildClick(value, "search", 'singleDropDown', "")
                                                                            }
                                                                        }}
                                                                        singleSelection={true}
                                                                        removeTopOptions={true}
                                                                        widthClass={'minWidth220'}
                                                                        className={'lg:right-auto right-0'}
                                                                    />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            <div className='py-1 lg:w-1/5 md:w-1/2 w-full lg:pl-3'>
                                                                <p className='b-block mb-0 truncate'>Min&nbsp;Instance&nbsp;Count</p>
                                                                {pageMode !== 'View' ?
                                                                    <div className='flex'>
                                                                        <input
                                                                            type='text'
                                                                            placeholder='Enter max instance count'
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                                            // maxlength='128'
                                                                            value={state['min_instance_count_'+index+'_'+cIndex] ? state['min_instance_count_'+index+'_'+cIndex] : ''}
                                                                            onChange={e => handleInnerInputChange(index, cIndex, 'min_instance_count', onlyNumeric(e.target.value))}
                                                                        />
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 text-white'>{state['min_instance_count_'+index+'_'+cIndex] ? state['min_instance_count_'+index+'_'+cIndex] : ''}</p>
                                                                }
                                                            </div>
                                                            <div className='py-1 lg:w-1/5 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                                                <p className='b-block mb-0 flex truncate'>
                                                                    Max&nbsp;Instance&nbsp;Count <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                                                    {state.inputValidationError && (!state['max_instance_count_'+index+'_'+cIndex]) ?
                                                                        <span className='mb-0 text-red ml-2'>required</span>
                                                                    : null}
                                                                </p>
                                                                {pageMode !== 'View' ?
                                                                    <div className='flex'>
                                                                        <input
                                                                            type='text'
                                                                            placeholder='Enter max instance count'
                                                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                                            // maxlength='128'
                                                                            value={state['max_instance_count_'+index+'_'+cIndex] ? state['max_instance_count_'+index+'_'+cIndex] : ''}
                                                                            onChange={e => handleInnerInputChange(index, cIndex, 'max_instance_count', onlyNumeric(e.target.value))}
                                                                        />
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 text-white'>{state['max_instance_count_'+index+'_'+cIndex] ? state['max_instance_count_'+index+'_'+cIndex] : ''}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div className='flex self-center mt-3'>
                                                                <label className="mb-0">
                                                                    <input type="checkbox" 
                                                                        className="mt-1" 
                                                                        checked={state['efa_enabled_'+index+'_'+cIndex] ? true : false}
                                                                        onChange={(event) => {
                                                                            if(pageMode !== 'View') {
                                                                                setState(prevState => ({ ...prevState, ['efa_enabled_'+index+'_'+cIndex]: !state['efa_enabled_'+index+'_'+cIndex]}))
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span className="slider round small pl-1"></span>
                                                                </label>
                                                                <span className="mb-0 text-lightGray f16">Use EFA</span>
                                                            </div>
                                                            <div className='flex self-center mt-3'>
                                                                <label className="mb-0">
                                                                    <input type="checkbox" 
                                                                        className="mt-1" 
                                                                        checked={state['placement_group_enabled_'+index+'_'+cIndex] ? true : false}
                                                                        onChange={(event) => {
                                                                            if(pageMode !== 'View') {
                                                                                setState(prevState => ({ ...prevState, ['placement_group_enabled_'+index+'_'+cIndex]: !state['placement_group_enabled_'+index+'_'+cIndex]}))
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span className="slider round small pl-1"></span>
                                                                </label>
                                                                <span className="mb-0 text-lightGray f16 small">Use placement group</span>
                                                            </div>
                                                            <div className='flex self-center mt-3'>
                                                                <label className="mb-0">
                                                                    <input type="checkbox" 
                                                                        className="mt-1" 
                                                                        checked={state['disable_simultaneous_multithreading_'+index+'_'+cIndex] ? true : false}
                                                                        onChange={(event) => {
                                                                            if(pageMode !== 'View') {
                                                                                setState(prevState => ({ ...prevState, ['disable_simultaneous_multithreading_'+index+'_'+cIndex]: !state['disable_simultaneous_multithreading_'+index+'_'+cIndex]}))
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span className="slider round small pl-1"></span>
                                                                </label>
                                                                <span className="mb-0 text-lightGray f16">Turn off multithreading</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {pageMode !== 'View' ?
                                                        <div className='py-1 lg:w-1/5 w-full self-center'>
                                                            <div className='flex justify-center mt-3'>
                                                                {state['computeArray_'+index].length > 1 ?
                                                                    <span className={`cursor-pointer text-red f16 self-center ml-3`} onClick={() => removeCRSection('computeArray', index, cIndex, j)}> Remove</span>
                                                                : null}
                                                                {(state['computeArray_'+index].length - 1) === j ?
                                                                    <span className={`cursor-pointer text-info f16 ml-3`} onClick={() => addSection('computeArray_'+index)}>Add</span>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <p className='text-white f16 mt-3'>Scripts</p>
                                <div className='rounded-md bg-dark2 p-3 mb-3'>
                                    <div className={'flex'}>
                                        <div className='py-1 w-full self-center'>
                                            <div className='flex'>
                                                <label className="mb-0">
                                                    <input type="checkbox" 
                                                        className="mt-1" 
                                                        checked={state['showNodeScript_'+index] ? true : false}
                                                        onChange={() => {
                                                            setState(prevState => ({ ...prevState, ['showNodeScript_'+index]: !state['showNodeScript_'+index] }))
                                                        }}
                                                    />
                                                </label>
                                                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Run script on node configured</span>
                                            </div>
                                        </div>
                                    </div>
                                    {state['showNodeScript_'+index] ? 
                                        <div className={'flex my-3'}>
                                            <div className='py-1 w-11/12'>
                                                <div className='flex justify-between'>
                                                    {/* <label className='self-center m-0'>Node Configured Script</label> */}
                                                    {state.hasError && !state.on_node_configured_script ? (
                                                        <small className='text-issues'>required</small>
                                                    ) : null}
                                                </div>
                                                
                                                <input
                                                    type='text'
                                                    placeholder='Enter script'
                                                    className='border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full'
                                                    // maxlength='128'
                                                    value={state['on_node_configured_script_'+index] ? state['on_node_configured_script_'+index] : ''}
                                                    onChange={e => {
                                                        setState(prevState => ({ ...prevState, ['on_node_configured_script_'+index]: e.target.value}))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    : null}
                                </div>

                                <p className='b-block text-white my-2'>Root Volume</p>
                                <div className='rounded-md bg-dark2 p-3 mb-3'>
                                    <div className='flex flex-wrap mt-2'>
                                        <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                            <div className='flex justify-between'>
                                                <label className='self-center m-0 text-lightGray'>Volume Type</label>
                                                {state.hasError && state['volume_type_'+index] ? 
                                                    <small className='text-issues'>required</small>
                                                : null}
                                            </div>
                                            <div className=''
                                                onClick={(event) => {
                                                    if(!state['showVolumeType_'+index]) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', 'showVolumeType_'+index)
                                                    }
                                                }}
                                            >
                                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 cursor-pointer ${state['volume_type_'+index] ? 'text-white' : 'text-lightGray'}`}>
                                                    {state['volume_type_'+index] ? state['volume_type_'+index] : 'Select'}
                                                    <Icon icon="icon-park-solid:down-one" className={`${state['volume_type_'+index] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                </p>
                                                {state['showVolumeType_'+index] ?
                                                    <div className='relative'>
                                                        <MultiSelectSection
                                                            options={state.volumeTypes ? state.volumeTypes : []}
                                                            selectedValues={state['volume_type_'+index] ? state['volume_type_'+index] : ''}
                                                            callbackMultiSelect={(value) => {
                                                                if(!value || typeof(value) === 'string') {
                                                                    setState(prevState => ({ ...prevState,  ['volume_type_'+index]: value }))
                                                                } else {
                                                                    value.preventDefault()
                                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                                }
                                                            }}
                                                            singleSelection={true}
                                                            widthClass={'minWidth220'}
                                                            removeTopOptions={true}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        <div className='py-1 lg:w-1/3 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                            <p className='b-block mb-0'>Root Volume Size</p>
                                            {pageMode === 'View' ?
                                                <p className='mb-0 text-white'>{state['volume_size_'+index] ? state['volume_size_'+index] : '-'}</p>        
                                            :
                                                <input
                                                    type='text'
                                                    placeholder='Enter volume size'
                                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                    // maxlength='128'
                                                    value={state['volume_size_'+index] ? state['volume_size_'+index] : ''}
                                                    onChange={e => handleInputChange(index, 'volume_size', onlyNumeric(e.target.value))}
                                                />
                                            }
                                        </div>
                                        <div className='flex self-center mt-3 lg:w-1/3 md:w-1/2 w-full lg:pl-3 pl-0'>
                                            {pageMode === 'View' ?
                                                <React.Fragment>
                                                <p className='b-block mb-0'>Encryption</p>
                                                <p className='mb-0 text-white'>{state['volume_encryption_'+index] ? "'True" : 'False'}</p>
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                <label className="mb-0">
                                                    <input type="checkbox" 
                                                        className="mt-1" 
                                                        checked={state['volume_encryption_'+index] ? true : false}
                                                        onChange={() => {
                                                            setState(prevState => ({ ...prevState, ['volume_encryption_'+index]: !state['volume_encryption_'+index]}))
                                                        }}
                                                    />
                                                    <span className="slider round small pl-1"></span>
                                                </label>
                                                <span className="mb-0 text-lightGray f16">Encrypt root volume</span>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <p className='b-block text-white my-2'>S3 Access</p>
                                <div className='rounded-md bg-dark px-2 py-2 mb-3'>
                                    {state['s3AccessArray_'+index] && state['s3AccessArray_'+index].map((sIndex, x) => {
                                        return(
                                        <div key={'s3_'+index+'_'+sIndex} className='rounded-md bg-dark2 p-3 my-3'>
                                            <div className='flex flex-wrap mt-2'>
                                                <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                                    <p className='b-block mb-0'>
                                                        Bucket name
                                                        {state.inputValidationError && (!state['bucket_name_'+index+'_'+sIndex]) ?
                                                            <span className='mb-0 text-red ml-2'>required</span>
                                                        : null}
                                                    </p>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter bucket name'
                                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                        // maxlength='128'
                                                        value={state['bucket_name_'+index+'_'+sIndex] ? state['bucket_name_'+index+'_'+sIndex] : ''}
                                                        onChange={e => {
                                                            setState(prevState => ({ ...prevState, ['bucket_name_'+index+'_'+sIndex]: e.target.value }))
                                                        }}
                                                    />
                                                </div>
                                                <div className='py-1 lg:w-1/3 md:w-1/2 w-full md:pl-3 self-center md:mt-0 mt-3'>
                                                    <label className="mb-0">
                                                        <input type="checkbox" 
                                                            className="mt-1" 
                                                            checked={state['enable_write_access_'+index+'_'+sIndex] ? true : false}
                                                            onChange={() => {
                                                                setState(prevState => ({ ...prevState, ['enable_write_access_'+index+'_'+sIndex]: !state['enable_write_access_'+index+'_'+sIndex] }))
                                                            }}
                                                        />
                                                        <span className="slider round small pl-1"></span>
                                                    </label>
                                                    <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Enable write access</span>
                                                </div>
                                                <div className='py-1 lg:w-1/3 w-full lg:pl-3 flex justify-end self-center'>
                                                    <div className='flex justify-center'>
                                                        {state['s3AccessArray_'+index].length > 1 ?
                                                            <span className={`cursor-pointer text-red f16 self-center ml-3`} onClick={() => removeCRSection('s3AccessArray', index, sIndex, x)}> Remove</span>
                                                        : null}
                                                        {(state['s3AccessArray_'+index].length - 1) === x ?
                                                            <span className={`cursor-pointer text-info f16 ml-3`} onClick={() => addSection('s3AccessArray_'+index)}>Add</span>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                                                
                                <div className='flex pt-2'>
                                    <div className='py-1 w-11/12'>
                                        <p className='b-block mb-0'>IAM Policies</p>
                                        {pageMode !== 'View' ?
                                            <div className="flex">
                                                <input
                                                    type='text'
                                                    placeholder='Enter policies'
                                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                    // maxlength='128'
                                                    value={state['input_policy_'+index] ? state['input_policy_'+index] : ''}
                                                    onChange={e => handleMultiInputChange(index, 'input_policy', e.target.value)}
                                                />
                                                <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 cursor-pointer' onClick={() => addDetails(index)} />
                                            </div>
                                        : 
                                            <p className='mb-0 text-white'>{state['input_policy_'+index] ? state['input_policy_'+index] : ''}</p>
                                        }
                                        {state['policies_'+index] && Array.isArray(state['policies_'+index]) && state['policies_'+index].length ? 
                                            <div className='flex flex-wrap'>
                                                {state['policies_'+index].map(row => {
                                                    return(
                                                        <span key={'policies_'+index+'_'+row} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center f14 text-info px-2'>{row}
                                                            {pageMode !== 'View' ?
                                                                <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-white ml-2 self-center cursor-pointer' onClick={ () => removefromList(index, row, 'policies') } />
                                                            : null}
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        : null}
                                    </div>
                                </div>

                                <div className='py-3 w-full'>
                                    <p className='b-block mb-0'>Tags</p>
                                    {pageMode !== 'View' ?
                                        <div className='flex flex-wrap'>
                                            <div className='md:w-5/12 w-full'>
                                                <input
                                                    type='text'
                                                    placeholder='Enter Key'
                                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full w-30`}
                                                    // maxlength='128'
                                                    value={state['tag_key_'+index] ? state['tag_key_'+index] : ''}
                                                    onChange={e => handleInputChange(index, 'tag_key', e.target.value)}
                                                />
                                            </div>
                                            <div className='md:w-5/12 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2'>
                                                <input
                                                    type='text'
                                                    placeholder='Enter value'
                                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                    // maxlength='128'
                                                    value={state['tag_value_'+index] ? state['tag_value_'+index] : ''}
                                                    onChange={e => handleInputChange(index, 'tag_value', e.target.value)}
                                                />
                                            </div>
                                            <div className='py-1 md:w-1/12 w-full pl-3 self-center'>
                                                <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 cursor-pointer' onClick={() => addNewTagValue(index)} />
                                            </div>
                                        </div>
                                    : null}
                                    {state['selected_tags_'+index] && state['selected_tags_'+index].length ? 
                                        <div className='flex flex-wrap'>
                                            {state['selected_tags_'+index].filter(e => e.value !== 'All').map((tag, tIndex) => {
                                                return(
                                                    <span key={'tag_'+index+'_'+tIndex} className='flex border rounded-full border-gray3 mr-2 mt-2 self-center f14 text-info px-2 breakWhiteSpace' id={'tag_'+tIndex}>
                                                        <div className='truncate'  style={{maxWidth: '250px'}}>
                                                            {tag.key +' : '+tag.value}
                                                        </div>
                                                        {pageMode !== 'View' ?
                                                            <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-white ml-2 self-center cursor-pointer' onClick={ () => removeTagSection(tag, index) } />
                                                        : null}
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    : null}
                                </div>
                                {pageMode !== 'View' ?
                                    <div className='flex w-full justify-end'>
                                        {state.queueArray.length > 1 ?
                                            <span className={`cursor-pointer text-red f16 self-center ml-4`} onClick={() => removeSection('queueArray', index, i)}> Remove Queue</span>
                                        : null}
                                        {(state.queueArray.length - 1) ===  i ?
                                            <span className={`cursor-pointer text-info f16 self-center ml-4`} onClick={() => addSectionQuerySection('queueArray')}> Add Queue</span>
                                        : null}
                                    </div>
                                : null}
                            </div>
                        )
                    })}

                    <div className={`flex flex-wrap mb-2 mt-4 justify-end`}>
                        <div className='py-1  self-center mr-3'>
                            <label className="mb-0">
                                <input type="checkbox" 
                                    className="mt-1" 
                                    checked={state.force_update ? true : false}
                                    onChange={() => {
                                        setState(prevState => ({ ...prevState, force_update: !state.force_update }))
                                    }}
                                />
                                <span className="slider round small pl-1"></span>
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2">Force update: Edit the cluster while the compute fleet is still running</span>
                        </div>
                        {pageMode !== 'View' ?
                            state.saveLoading ?
                                <button className='btn bg-info px-2 py-1 self-center rounded-md'>
                                    {pageMode === 'Edit' ? 'Updating' : 'Saving'}<Spinner size='sm' color='light' className='ml-2' />
                                </button>
                            : 
                                <button className={`flex justify-end cursor-pointer btn bg-info text-white px-2 py-1 rounded-md`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>{pageMode === 'Edit' ? 'Update' : 'Save'}</button>
                        : null}
                        <button className={`ml-2 flex justify-end cursor-pointer btn bg-lightGray text-white px-2 py-1 rounded-md`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateQueuePanel