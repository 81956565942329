import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { listBuilds } from '../../../actions/ImagebuilderAction'
// import WorkstationSidePanel from './WorkstationSidePanel'
import { CSVLink } from 'react-csv';
import {Icon} from "@iconify/react";
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
// import Search from '../../common/SearchWithHiddenInput';
import { momentDateGivenFormat } from '../../../utils/utility';
import DetailsSidePanel from './DetailsSidePanel';

let searchInput = null
const BuildSection = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        perPage: 100,
        callListBuilds: true
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.action && row.link === currentPath) {
                    actionsAllowed = row.action
                }

                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.callListBuilds) {
            setState(prevState => ({ ...prevState, callListBuilds: false }))
            let params = {}
            if(props.selectedProvider && props.selectedProvider !== '') {
                params.provider = props.selectedProvider.toLowerCase()
            }
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedImageType) {
                params.image_type = props.selectedImageType
            }
            if(props.selectedImage) {
                params[props.selectedProvider === 'docker' ? 'image_name' : 'os'] = props.selectedImage
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
            params.size = state.perPage

            // if(state.nextCatalogToken && state.nextCatalogToken.length) {
            //     params.next_token = state.nextCatalogToken
            // }

            dispatch(listBuilds(params))
                .then((response) => {
                    if(response) {
                        let totalResults = response && response.results ? response.results : []

                        setState(prevState => ({ ...prevState, detailsFormat: response.build_details ? response.build_details : [], buildsList: totalResults, filterListBuilds: totalResults, totalRecords: response.total ? response.total : 0, showLoading: false  }));
                    }
                })
        }
    }, [dispatch, state.perPage, state.callListBuilds, props.selectedProvider, props.selectedAccount, props.selectedImage, props.selectedImageType, props.selectedRegion, state.searchText])

    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <svg className="animate-spin h-5 w-5 ml-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
            {state.showDetails && state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <DetailsSidePanel
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                    fromPage={'Build'}
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, showDetails: false }))}
                />
            : null}
            <div className='p-2 overflow-auto'>
                <div className='flex flex-wrap justify-between my-3'>
                    <p className='mb-0 self-center md:w-1/2 w-full'>
                        <small>Showing {state.filterListBuilds && state.filterListBuilds.length} out of total {state.totalRecords} builds(s)</small>
                        {props.selectedProvider?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{props.selectedProvider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex flex-wrap self-center'>
						<div className='flex md:w-1/2 w-full md:ml-3 ml-0 md:mt-0 mt-2'>
							{state.buildsList && state.buildsList.length ?
								<CSVLink
									data={state.filterListBuilds ? state.filterListBuilds : []} 
									headers={state.tableHeaders ? state.tableHeaders : []}
									filename={'job-lists.csv'}
									className={'self-center'}
									target='_blank'
								>
									<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
								</CSVLink>
							: null}
                            <div className='flex border border-lightGray rounded-md'>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`text-white w-5 self-center`} width={25} height={25} />
                                <div className={`w-11/12`}>
                                    <input
                                        value={state.searchText}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                            clearTimeout(searchInput)
                                            if(e.target.value.length > 2) {
                                                searchInput = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, startRecord: 0, currentPage: 1, totalPages: 1, listBuilds: [], filterListBuilds: [], showLoading: true, callListBuilds: true }))}, 1000); 
                                            } else if(!e.target.value.length) {
                                                setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, listBuilds: [], filterListBuilds: [], showLoading: true, callListBuilds: true }))
                                            }
                                        }}
                                        type='text'
                                        className={`bg-transparent text-white text-md pl-1 py-1`}
                                        placeholder='Search'
                                    />
                                </div>
                            </div>
							{/* {state.buildsList && state.buildsList.length ?
								<Search
									data={state.buildsList ? state.buildsList : []}
									topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
									searchClassName={'px-2'}
									searchIconColor={'text-white '}
									searchPlaceHolder={'Search....'}
									className={'bg-transparent text-white'}
									widthHeight={25}
									filteredData={(filterListBuilds) => setState(prevState => ({ ...prevState, filterListBuilds }))}
								/>
							: null} */}
						</div>
					</div>
				</div>
				{state.filterListBuilds && state.filterListBuilds.length ?
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: 'Image',
								accessor: 'image_name',
                                Cell: cellInfo => (
                                    <div className='cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original, showDetails: true }))}>
                                        <p className='w-full text-info'>{cellInfo.row.original.image_name}</p>
                                        <p className='w-full'>{cellInfo.row.original.image_details}</p>
                                        </div>
                                ),
								width: 250
							},
							{
								Header: 'Created at',
								accessor: 'created_at',
                                Cell: cellInfo => (
                                    <span>
                                        {cellInfo.row.original.created_at ? momentDateGivenFormat(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : ''}
                                    </span>
                                ),
								width: 180,
							},
							{
								Header: 'Build started time',
								accessor: 'build_started_time',
                                Cell: cellInfo => (
                                    <span>
                                        {cellInfo.row.original.build_started_time ? momentDateGivenFormat(cellInfo.row.original.build_started_time, 'DD MMM YYYY HH:mm') : ''}
                                    </span>
                                ),
								width: 180,
							},
							{
								Header: 'Build completed time',
								accessor: 'build_completed_time',
                                Cell: cellInfo => (
                                    <span>
                                        {cellInfo.row.original.build_completed_time ? momentDateGivenFormat(cellInfo.row.original.build_completed_time, 'DD MMM YYYY HH:mm') : ''}
                                    </span>
                                ),
								width: 180,
							},
							{
								Header: 'Build no',
								accessor: 'build_number',
							},
							{
								Header: 'Build duration',
								accessor: 'build_duration_description',
                                width: 180
							},
							{
								Header: 'Vulnerabilities',
								accessor: 'vulnerabilities',
                                // Cell: cellInfo => (
                                //     <span className='border border-lightGray px-2 py-1 rounded-md'>
                                //         {cellInfo.row.original.vulnerabilities ? cellInfo.row.original.vulnerabilities : <span>&nbsp;</span>}
                                //     </span>
                                // ),
								width: 180,
							},
							{
								Header: 'Status',
								accessor: 'status',
                                Cell: cellInfo => (
                                    <span className={`border border-${cellInfo.row.original.status ? (cellInfo.row.original.status.toLowerCase() === 'successful' ? 'green' : cellInfo.row.original.status.toLowerCase() === 'in progress' ? 'warning' : 'danger') : ''} text-${cellInfo.row.original.status ? (cellInfo.row.original.status.toLowerCase() === 'successful' ? 'green' : cellInfo.row.original.status.toLowerCase() === 'in progress' ? 'warning' : 'danger') : ''} rounded-full px-2 py-1`}>
                                        {cellInfo.row.original.status ? cellInfo.row.original.status : ''}
                                    </span>
                                ),
								width: 200,
							},
                            {
                                Header: ' ',
                                width: 1,
                            },
						]}
						data={state.filterListBuilds}
						perPage={20}
                        tableHead={'bg-dark2 text-white'}
                        tableBody={'text-black'}
                        tableBodyColor={'bg-black3'}
                        tableType={'table-light-hover'}
                        perPageClass={`bg-lightGray text-white rounded-md ml-2`}
                        textColor={'text-white'}
                        selectedColor={'bg-transparent'}
                        dashboard={true}
						showPaginationTop={false}
                        paginationColor={'text-white'}
						onClickRow={tableRow => {}}
					/>
				: 
					<div className='flex justify-center m-4'>
						<p>There are no data on this criteria. Please try adjusting your filter.</p>
					</div>
				}
            </div>
            </React.Fragment>
    );
};
export default BuildSection