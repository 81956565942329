/*************************************************
 * Collider
 * @exports
 * @file index.js
 * @author Prakash // on 19/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
let url = ""

const apiUrl = process.env;
if(apiUrl && apiUrl.REACT_APP_COLLIDER_API_URL) {
	url = apiUrl.REACT_APP_COLLIDER_API_URL
}

let billingEndPoint					= 'https://fr2vpnj1s6.execute-api.us-east-2.amazonaws.com/dev'
let scoreEndpoint					= 'https://0p72hzhak6.execute-api.us-east-2.amazonaws.com/score/'
let synchronizerEndPoint 			= 'https://ms4c1sh8ah.execute-api.us-east-2.amazonaws.com/dev'
let detectoreEndPoint           	= 'https://cc621f5412.execute-api.us-east-2.amazonaws.com/dev'
let configurationEndPoint 			= 'https://s5tqvnz50a.execute-api.us-east-2.amazonaws.com/dev'
let suppressorEndPoint				= 'https://bk0aotl5lh.execute-api.us-east-2.amazonaws.com/dev'



export const KIOSK_END_POJNT			= url
export const IMAGE_BUILDER_END_POINT 	= 'https://image-builder-internal.collider.invisibl.io'

export const BILLING_END_POINT			= billingEndPoint
export const SCORE_END_POINT			= scoreEndpoint
export const SYNCHRONIZER_END_POINT		= synchronizerEndPoint
export const DETECTORS_END_POINT		= detectoreEndPoint
export const CONFIGURATION_END_POINT	= configurationEndPoint
export const SUPPRESSOR_END_POINT		= suppressorEndPoint

//APis

// colliderEndPoint start
export const LOGIN = '/login' //signinAction
export const GET_USER_PROFILE = '/get-user-profile'
export const GET_USER_MENU = '/get-user-menu'

export const GET_JOB_STATUS_APIS = '/get-job-status-apis' //JobStatusAction
export const GET_JOB_COST_TREND = '/get-job-cost-trend' //JobStatusAction
export const GET_JOB_TOTAL_COST = '/get-job-total-cost' //JobStatusAction
export const LIST_JOB_DETAILS = '/list-job-details' //JobStatusAction
export const GET_JOB_LOGS = '/get-job-logs' //JobStatusAction
export const DOWNLOAD_JOB_LOGS = '/download-job-logs' //JobStatusAction
export const LIST_DOWNLOAD_RESULTS = '/list-download-results' //JobStatusAction
export const DELETE_DOWNLOAD_RESULTS = '/delete-download-results'
export const GET_JOB_METRICS = '/get-job-metrics' // JobStatusAction
export const START_SYSTEM_JOB_LOGS = '/start-system-job-logs' // JobStatusAction
export const GET_SYSTEM_JOB_LOGS = '/get-system-job-logs' // JobStatusAction

export const GET_JOB_FILTERS = '/get-job-filters'  //JobStatusAction
export const SUBMIT_JOB = '/jobs/submit-job'  //JobStatusAction
export const CANCEL_JOB = '/cancel-job'

export const GET_COST_TREND_BREAK_DOWN = '/get-cost-trend-break-down' //spendAction
export const GET_JOB_UTILIZATION_TREND = '/get-job-utilization-trend' //spendAction

export const LIST_DISK_DETAILS = '/list-disk-details' //observability
export const GET_DISK_DETAILS = '/get-disk-details' //observability
export const GET_DISK_METRICS = '/get-disk-metrics' //observability

export const GET_CLUSTER_METRICS = '/get-cluster-metrics' //observability
export const GET_CLUSTER_HEAD_NODE_METRICS = '/get-cluster-head-node-metrics' //observability
export const GET_QUEUE_METRICS = '/get-queue-metrics' //observability
export const LIST_TROUBLESHOOT_RESULTS = '/list-troubleshoot-results' //observability
export const LIST_ALERTS = '/list-alerts' //observability
export const GET_ALERTS_DAILY_TREND = '/get-alerts-daily-trend' //observability

export const LIST_CLUSTER_DETAILS = '/list-cluster-details' //clusterAction
export const GET_CLUSTER_DETAILS = '/get-cluster-details' //clusterAction
export const LIST_PROJECT_DETAILS = '/list-project-details' //clusterAction
export const LIST_CLUSTERS = '/list-clusters' //clusterAction
export const GET_CLUSTER_FILTERS ='/get-cluster-filters'
export const CREATE_CLUSTER = '/create-cluster' //clusterAction
export const UPDATE_CLUSTER = '/update-cluster' //clusterAction
export const DELETE_CLUSTER = '/delete-cluster' //clusterAction

export const GET_CLUSTER_COST_BREAKUP = '/get-cluster-cost-breakup'
export const GET_QUEUE_COST_BREAKUP = '/get-queue-cost-breakup'
export const GET_USER_COST_BREAKUP = '/get-user-cost-breakup'
export const GET_PROJECT_COST_BREAKUP = '/get-project-cost-breakup'

export const LIST_USER_DETAILS = '/list-user-details'

export const LIST_QUEUES = '/list-queues' //clusterAction
export const LIST_QUEUE_DETAILS = '/list-queue-details' //clusterAction
export const CREATE_QUEUE =  '/create-queue' //clusterAction
export const UPDATE_QUEUE =  '/update-queue' //clusterAction
export const DELETE_QUEUE =  '/delete-queue' //clusterAction
export const GET_QUEUE_FILTERS = '/get-queue-filters' //clusterAction
export const LIST_USERS_BY_CLUSTER = '/list-users-by-cluster'

export const HPC_INSERT_BUDGET_DETAILS = '/insert-budget-details' //hpcAction
export const HPC_UPDATE_BUDGET_DETAILS = '/update-budget-details' //hpcAction
export const HPC_LIST_BUDGET_DETAILS = '/list-budget-details' //hpcAction
export const HPC_DELETE_BUDGET_DETAILS = '/delete-budget-details' //hpcAction

export const GET_HPC_REPORT_QUERIES = '/reports/get-hpc-report-queries' //HpcReportAction
export const GET_HPC_REPORTS = '/reports/get-hpc-reports' //HpcReportAction

export const GET_COST_ALL_TAGS_KEYS = '/cost/get-all-tags-keys' //costAction.js
export const GET_COST_ALL_TAGS_VALUES = '/cost/get-all-tags-values' //costAction.js
export const GET_COST_RECOMMENDATIONS = '/cost/get-cost-recommendations' //costAction
export const START_POWER_TUNING = '/cost/start-power-tuning' //costAction
export const GET_POWER_TUNING_ASSETS = '/cost/get-power-tuning-assets' //costAction
export const GET_POWER_TUNING_RESULTS = '/cost/get-power-tuning-results' //costAction
export const GET_COST_FILTERS = '/cost/get-cost-filters' //costAction
export const GET_COST_FILTER_VALUES = '/cost/get-cost-filter-values' //costAction
export const GET_COST_TOTAL_COUNT = '/cost/get-cost-total-count' //costAction
export const LIST_COST_TEMPLATE = '/cost/list-cost-templates' //costAction
export const GET_COST_ALLOCATION_TAGS = '/cost/get-cost-allocation-tags' //costAction

export const SAVE_TEMPLATE = '/cost/save-template' //costAction
export const COST_GET_COST_TREND = '/cost/get-cost-trend' //costAction
export const GET_COST_QUERIES = '/cost/get-cost-queries' //costQueryAction
export const GET_COST_QUERY_RESULTS = '/cost/get-cost-query-results' //costQueryAction
export const GET_ANOMALY_TOTAL_COUNT = '/cost/get-anomaly-total-count' //costAction
export const GET_ANOMALY_DAILY_TREND = '/cost/get-anomaly-daily-trend' //costAction
export const LIST_ANOMALY_RESULTS = '/cost/list-anomaly-results' //costAction
export const GET_TOP_ANOMALIES = '/cost/get-top-anomalies' //costAction
export const COST_COMPARISON = '/cost/cost-comparison' //costAction
export const GET_BUDGET_DETAILS = '/cost/get-budget-details' //costAction
export const GET_BUDGET_FILTERS = '/cost/get-budget-filters' //costAction 
export const INSERT_BUDGET_DETAILS = '/cost/insert-budget-details' //costAction
export const UPDATE_BUDGET_DETAILS = '/cost/update-budget-details' //costAction
export const LIST_BUDGET_DETAILS = '/cost/list-budget-details' //costAction

export const LIST_DEPARTMENTS = '/budget/list-departments'
export const GET_DEPARTMENT_BUDGET = '/budget/get-department-budget'
export const UPDATE_BUDGET = '/budget/update-budget'
export const LIST_BUDGETS = '/budget/list-budgets'
export const GET_BUDGET_DASHBOARD = '/budget/get-budget-dashboard'
export const GET_BUDGET_DASHBOARD_MOCK_DATA = '/budget/get-mock-data'
export const GET_TOP_SPENDERS = '/budget/get-top-spenders'
export const GET_TOP_OVERSPENDERS = '/budget/get-top-overspenders'
export const GET_OVERSPENDERS_FORECAST = '/budget/get-overspenders-forecast'
export const GET_TOP_SPENDERS_FORECAST = '/budget/get-top-spenders-forecast'
export const GET_BUDGET_SUMMARY = '/budget/get-summary'
export const LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS = '/budget/list-departments-initiatives-projects-accounts'
export const GET_SPEND_COMPARISION_WITH_PREVIOUS_YEAR = '/budget/get-spend-comparison-with-previous-year'
export const GET_CLOUD_COMMITMENT_VS_SPEND = '/budget/get-cloud-commitment-vs-spend'
export const GET_CLOUD_PROVIDER_BUDGET = '/budget/get-cloud-provider-budget'
export const INSERT_CLOUD_PROVIDER_BUDGET = '/budget/insert-cloud-provider-budget'
export const GET_CURRENT_CLOUD_COMMITMENT_VS_SPEND = '/budget/get-current-cloud-commitment-vs-spend'
export const GET_CLOUD_PROVIDER_COMMITMENT = '/budget/get-cloud-provider-commitment'
export const GET_BUDGET_OBSERVATIONS ='/budget/get-observations' //complianceDashboardAction
export const CREATE_DEPARTMENT = '/budget/create-department'
export const EDIT_DEPARTMENT = '/budget/edit-department'
export const DELETE_DEPARTMENT = '/budget/delete-department'
export const LIST_DEPARTMENTS_MAPPINGS = '/budget/list-departments-mappings'
export const GET_UNTAGGED_RESOURCES = '/budget/get-untagged-resources'
export const GET_CURRENCY_CONVERSION_DETAILS = '/budget/get-currency-conversion-details'

export const GET_QUERIES = '/queries/get-queries' //QueryAction
export const START_QUERY_EXECUTION = '/queries/start-query-execution' //QueryAction
export const GET_QUERY_RESULT = '/queries/get-query-results' //QueryAction
export const SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY = '/queries/set-favourite-query' //QueryAction
export const UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY = '/queries/unset-favourite-query' //QueryAction

export const GET_MONITORING_METRICS_BY_TREND = '/monitoring/get-monitoring-metrics-by-trend' //metricsAction
export const GET_MONITORING_METRICS_BY_TREND_V2 = '/monitoring/get-monitoring-metrics-by-trend-v2' //metricsAction
export const GET_SRE_DETAILS = '/monitoring/get-sre-metrics' //assetAction
export const GET_PEAK_DIP_DETECTION_RESULTS = '/get-peak-dip-detection-results'


export const LIST_COVERED_ASSETS = '/resources/list-covered-assets' //detectorsAction
export const GET_COVERED_SERVICES = '/resources/get-covered-services' //detectorsAction
export const GET_RESOURCE_SEARCH_META_RESULTS = '/resources/getSearchMetaResults' //commonActionNew
export const GET_SEARCH_META_RESULTS = '/resources/get-search-meta-results' //commonActionNew
export const SAVE_SEARCH_META_RESULTS = '/resources/save-search-meta-results'
export const GET_VARIOUS_COUNTS_BY_ACCOUNT = '/get-various-counts-by-account' //detectorsAction	
export const SET_FAVOURITE_QUERY = '/resources/set-favourite-query' //commonActionNew
export const UNSET_FAVOURITE_QUERY = '/resources/unset-favourite-query' //commonActionNew
export const LIST_ASSETS = '/resources/listAssets'
export const LIST_ADVANCED_SEARCH_ASSETS = '/resources/listAdvancedSearchAssets'
export const GET_ADVANCED_TOTAL_ASSETS_COUNT = '/resources/getAdvancedTotalAssetCount'
export const GET_ADVANCED_SEARCH_LIST = '/resources/getAdvancedSearchResults' //assetAction
export const GET_TOTAL_ASSET_COUNT = '/resources/getTotalAssetCount'
export const GET_DAILY_ASSET_COUNT = '/resources/getDailyAssetCount' //assetAction
export const GET_ASSET_DETAIL_EXT = '/resources/getAssetDetailExt'
export const GET_DAILY_ACTIVE_ASSET_COUNT = '/resources/getDailyActiveAssetCount' //comparisonAction
export const GET_ASSET_COUNT_BY_PIRCING_MODEL = '/resources/getAssetCountByPricingModel' //comparisonAction
export const GET_SCORE_DETAILS = '/resources/get-score-details' //comparisonAction
export const GET_TOP_EVENTS = '/resources/getTopAssetEvents' //detectorsAction	
export const GET_RECENT_EVENTS = '/resources/getAssetEvents' //detectorsAction	
export const GET_EVENTS_GRAPH_COUNT = '/resources/getAssetEventDailyCount' //detectorsAction


export const GET_ASSET_NETWORK_DETAILS = '/getAssetNetworkDetails' //assetAction
export const GET_ASSET_DETAIL = '/resources/getAssetDetail' //detectorsAction	


export const LIST_PROVIDERS = '/commonservices/list-user-providers' //commonActionNew
export const LIST_REGIONS = '/commonservices/list-user-regions' //commonActionNew
export const LIST_SERVICES = '/commonservices/list-user-services' //commonActionNew
export const LIST_ACCOUNTS = '/commonservices/list-user-accounts' //commonActionNew
export const LIST_CATEGORIES = '/commonservices/list-user-categories' //commonActionNew
export const GET_USER_ORGANIZATION = '/commonservices/get-user-organization' //commonActionNew
export const LIST_EVENT_SOURCES = '/commonservices/list-event-sources' //commonActionNew
export const CAPTURE_UI_ERRORS = '/commonservices/capture-ui-errors'  //commonActionNew
export const LIST_ORGANIZATIONS = '/commonservices/list-organizations' //commonActionNew
export const LIST_MODULES = '/commonservices/list-modules' //commonActionNew
export const LIST_ACCOUNTS_DETAILS = '/commonservices/list-accounts' //commonActionNew
export const LIST_USERS = 'list-users' //commonActionNew
export const LIST_PROJECTS = '/list-projects'
export const LIST_CLUSTET_FILTERS = '/list-cluster-filters'

export const GET_ALL_TAGS = '/tagging/get-all-tags' //commonActionNew and assetComparisionAction and governanceAction
export const GET_ALL_TAGS_KEYS = '/tagging/get-all-tags-keys' //commonActionNew
export const GET_ALL_TAGS_VALUES = '/tagging/get-all-tags-values' //commonActionNew
export const LIFECYCLE_NOTIFICATIONS = '/lifecycle-notification'
export const GET_LIFECYCLE_END_TIME = '/get-lifecycle-end-time'
export const DISABLE_LIFECYCLE_POLICY ='/lifecycle/disable-lifecycle-policy'


export const LIST_ALL_USER_DETAILS = '/list-all-user-details'
export const CREATE_USER = '/create_user'
export const UPDATE_USER = '/update_user'
export const DELETE_USER = '/delete_user'
export const LIST_ROLE_DETAILS = '/list-role-details'
export const LIST_USER_FILTERS = '/list-user-filters'

export const GET_ONBOARDED_ACCOUNTS_LIST = '/account-onboarding/get-onboarded-accounts-list'
export const ONBOARD_AWS_ACCOUNT = '/account-onboarding/onboard-aws-account'
export const DELETE_ONBOARDED_ACCOUNT = '/account-onboarding/delete-onboarded-account'
export const GET_ROLE_YAML = '/account-onboarding/get-role-yaml'

export const LIST_ONBOARDING_CLUSTERS = '/cluster-onboarding/list-clusters'
export const ONBOARD_CLUSTERS = '/cluster-onboarding/onboard-cluster'
export const GET_ONBOARDED_CLUSTER_DETAILS = '/cluster-onboarding/get-cluster-details'

export const GET_ASSET_SG_DETAILS = '/getAssetSGDetails' //assetAction
export const GET_ASSET_STORAGE_DETAILS = '/getAssetStorageDetails' //assetAction
export const LIST_CLUSTER_ASSETS = '/cluster-resources/list-cluster-assets'
export const LIST_CLUSTER_NAMES = '/cluster-resources/list-cluster-names'


//billingEndPoint
export const GET_TOTAL_BILLING = '/get-total-billing'
//billingEndPoint

export const GET_SCORE = 'get-score' //checksDashboardAction

//detectoreEndPoint
export const INSERT_CONFIGURATON = '/insert-configuration' //configurationAction and governanceAction
export const UPDATE_CONFIGURATION = '/update-configuration' //configurationAction and governanceAction
export const DELETE_CONFIGURATON = '/delete-configuration' //configurationAction and governanceAction
export const GET_CONFIGURATION = '/get-configuration' //detectorsAction

export const GET_BILLING_SNAPSHOT = '/get-asset-billing-snapshot' //detectorAction
export const GET_IDENTIFIERS = '/get-identifiers' //configurationAction
export const GET_IDENTIFIERS_TOTAL_COUNT = '/get-identifiers-total-count' //configurationAction
export const LIST_CONFIGURATION = '/list-configuration' //governanceAction


export const GET_AGGREGATED_ACCOUNTS_BY_REGION = '/get-aggregated-accounts-by-region' //detectorsAction
export const GET_AGGREGATED_IDENTIFIER_RESULTS = '/get-aggregated-identifier-results' //detectorsAction	
export const GET_SERVICE_QUOTA_DETAILS = '/get-service-quota-details' //detectorsAction	
export const GET_TOTAL_COUNT = '/get-total-count' //detectorAction
export const GET_DAY_WISE_COUNT = '/get-day-wise-count' //detectorAction
export const GET_ALL_IDENTIFIERS_RESULTS = '/get-identifier-results' //detectorAction
export const GET_LATEST_IDENTIFIER_RESULTS = '/get-latest-identifier-results' //detectorAction
export const RESOLVE_ISSUE ='/resolve-issue' //detectorAction and dlpAction
	
export const GET_DAILY_ASSET_AVAILABILITY = '/getDailyAssetAvailability' //detectorsAction	
export const GET_EVENT_BY_BOUNDARY = '/getEventsByBoundary' //detectorsAction	
export const GET_MONITORING_SNAPSHOT = 'monitoringproxysnapshot/getMonitoringSnapshot' //detectorAction

//detectoreEndPoint

//suppressorEndPoint start

export const LIST_SUPPRESSION = '/list-suppression' //suppressorsAction
export const INSERT_SUPPRESSION = '/insert-suppression' //suppressorsAction
export const DELETE_SUPPRESSION = '/delete-suppression' //suppressorsAction

export const UPDATE_SUPPRESSION = '/update-suppression' //not used

//suppressorEndPoint end

export const LIST_ALARMS = '/aiops/list-alarms' //aiopsAction
export const CREATE_ALARM = '/aiops/create-alarm' //aiopsAction
export const GET_AGGREGATED_EVENTS = '/aiops/get-aggregated-events' //aiopsAction

export const GET_COMPLIANCE_BY_EVENT = '/get-compliance-by-event' //complianceDashboardAction
export const GET_COMPLIANCE_BY_ASSET = '/get-compliance-by-asset' //complianceDashboardAction
export const GET_COMPLIANCE_BY_SERVICE_COUNT = '/get-compliance-by-service-count' //complianceDashboardAction
export const GET_COMPLIANCE_BY_ROLE = '/get-compliance-by-role' //complianceDashboardAction
export const GET_COMPLIANCE_CONTROL_DETAILS = '/get-compliance-control-details' //complianceDashboardAction
export const GET_COMPLIANCE_SUB_CONTROL_DETAILS = '/get-compliance-sub-control-details' //complianceDashboardAction
export const GET_COMPLIANCE_MINOR_CONTROL_DETAILS = '/get-compliance-minor-control-details' //complianceDashboardAction
export const GET_RISK_WISE_COMPLEXITY = '/get-risk-wise-complexity' //complianceDashboardAction
export const GET_PASS_FAIL_DETAILS ='/get-pass-fail-details' //complianceDashboardAction
export const GET_OBSERVATIONS ='/get-observations' //complianceDashboardAction
export const GET_RISK_BY_ROLE ='/get-risk-by-role' //complianceDashboardAction
export const GET_COMPLIANCE_BY_TAG ='/get-compliance-by-tag' //complianceDashboardAction
export const GET_TRANSITION_TREND ='/get-transition-trend' //complianceDashboardAction
export const GET_DAY_WISE_ASSET_COUNT ='/get-day-wise-asset-count' //complianceDashboardAction


export const LIST_IDENTITIES ='/list-identities' //userinsightsAction


export const SIGNUP = '/signup' //registerAction


export const ADD_ACCOUNT = '/addaccount' //AccountRegisterAction


export const GET_COLLECTED_DATA = '/get-collected-data'
export const LIST_TEST_DETAILS = '/list-test-details'

export const LIST_WORKSTATION_RESULTS = '/list-workstation-results' //catalogAction
export const LIST_WORKSTATION_CATALOG_DETAILS = '/list-workstation-catalog-details' //catalogAction
export const SUBSCRIBE_CATALOG = '/subscribe-catalog' //catalogAction
export const GET_WORKSTATION_METRICS = '/get-workstation-metrics' //catalogAction
export const LIST_STACK_EVENTS = '/list-stack-events' //catalogAction
export const APPLY_WORKSTATION_ACTIONS = '/apply-workstation-actions' //catalogAction

export const LIST_WORKSTATION_REQUESTS = '/list-workstation-requests' //catalogAction
export const LIST_WORKSTATION_REQUEST_HISTORY = '/list-workstation-request-history' //catalogAction
export const CHANGE_WORKSTATION_STATUS = '/change-workstation-status' //catalogAction

export const LIST_LIFECYCLE_POLICIES = '/lifecycle/list-lifecycle-policies' //catalogAction
export const LIST_LIFECYCLE_RULES = '/lifecycle/list-lifecycle-rules' //catalogAction
export const PUT_LIFECYCLE_POLICIES = '/lifecycle/put-lifecycle-policy' //catalogAction
export const DELETE_LIFECYCLE_POLICIES = '/lifecycle/delete-lifecycle-policies'
export const GET_CATALOG_FILTERS = '/get-catalog-filters'

// File Browser


export const LIST_GOVERNANCE_POLICIES = '/governance/list-governance-policies' //newGovernanceAction
export const GET_MASTER_GOVERNANCE_RULES = '/governance/get-master-governance-rules' //newGovernanceAction
export const GET_POLICY_DETAILS = '/governance/get-policy-details' //newGovernanceAction
export const CREATE_GOVERNANCE_POLICY = '/governance/create-governance-policy' //newGovernanceAction
export const EDIT_GOVERNANCE_POLICY = '/governance/edit-governance-policy' //newGovernanceAction
export const NEW_GOVERNANCE_GET_TOTAL_COUNT = '/governance/get-total-count' //newGovernanceAction
export const NEW_GOVERNANCE_GET_DAY_WISE_COUNT = '/governance/get-day-wise-count' //newGovernanceAction
export const NEW_GOVERNANCE_GET_TRANSITION_TREND = '/governance/get-transition-trend' //newGovernanceAction
export const GET_GOVERNANCE_VIOLATIONS = '/governance/get-governance-violations' //newGovernanceAction
export const NEW_GOVERNANCE_GENERATE_IAM_POLICY = '/governance/generate-iam-policy' //newGovernanceAction
export const NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES = '/governance/get-governance-services' //newGovernanceAction
export const NEW_GOVERNANCE_GET_GOV_META_DATA = '/governance/get-gov-meta-data' //newGovernanceAction
export const NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS = '/governance/list-goveranance-results' //newGovernanceAction
export const LIST_GOVERNANCE_TYPE = '/governance/list-governance-type'
export const DELETE_GOVERNANCE_POLICY = '/governance/delete-governance-policy' //newGovernanceAction

// File Browser
export const GET_USER_FILES = '/filemanager/list-folders'
export const UPLOAD_USER_FILES = '/filemanager/upload-file'
export const CREATE_FOLDER = '/filemanager/create-folder'
export const DOWNLOAD_FILE = '/filemanager/download-file'
export const GET_STATS = '/filemanager/get-stats'
export const DELETE_FILE = '/filemanager/delete-file'
export const GET_FILE_CONTENT = '/filemanager/get-file-content'
export const SAVE_FILE_CONTENT = '/filemanager/save-file-content'
export const RENAME_FILE = '/filemanager/rename-file'
export const EXTRACT_FILE = '/filemanager/extract-file'


export const LIST_QOS_POLICIES = '/qos/list-qos-policies'
export const GET_QOS_PARAMETERS = '/qos/get-qos-parameters'
export const CREATE_QOS_POLICY = '/qos/create-qos-policy'
export const GET_QOS_POLICY = '/qos/get-qos-policy'
export const EDIT_QOS_POLICY = '/qos/edit-qos-policy'
export const REMOVE_QOS_POLICY = '/qos/remove-qos-policy'

export const QOS_LIST_ACCOUNTS = '/account/list-accounts'
export const QOS_CREATE_ACCOUNT = '/account/create-account'
export const QOS_UPDATE_ACCOUNT = '/account/update-account'
export const QOS_DELETE_ACCOUNT = '/account/delete-account'
export const GET_ACCOUNT_POLICY = '/account/get-account-policy'

export const GET_QUEUE_DETAILS = '/insights/get-queue-details'
export const GET_JOB_DETAILS = '/insights/get-job-details'
export const GET_QUEUE_AVAILABILITY_DETAILS = '/insights/get-queue-availability-details'


export const GET_LICENSE_TOP_RESULTS = '/license/get-license-top-results'
export const LIST_LICENSES = '/license/list-licenses'
export const LIST_LICENSE_APPLICATIONS = '/license/list-license-applications'

export const GET_JOB_BUDGET = '/get-job-budget'

export const GET_UPDATES = '/get-updates'


export const LIST_IMAGES =  '/imagebuilder/list-images'
export const LIST_BUILDS =  '/imagebuilder/list-builds'
export const GET_BUILD_LOGS =  '/imagebuilder/get-build-logs'
export const LIST_REPORTS = '/imagebuilder/list-reports'
export const DOWNLOAD_REPORT = '/imagebuilder/download-report'
export const GET_IMAGE_FILTERS = '/imagebuilder/get-image-filters'
export const SUBSCRIBE_IMAGE = '/imagebuilder/subscribe-image'
export const BUILD_IMAGE = '/imagebuilder/build-image'
export const LIST_PACKAGES = '/imagebuilder/list-packages'
export const LIST_NOTIFICATIONS = '/imagebuilder/list-notifications'
export const SHARE_IMAGE = '/imagebuilder/share-image'
export const LIST_PLANS = '/imagebuilder/list-plans'
export const BUILD_PLAN = '/imagebuilder/build-plan'
export const SCAN_IMAGE = '/imagebuilder/scan-image'
export const LIST_SCHEDULES = '/imagebuilder/list-schedules'
export const CREATE_SCHEDULE = '/imagebuilder/create-schedule'
export const DELETE_SCHEDULE = '/imagebuilder/delete-schedule'
export const GET_TRIGGER_DATES = '/imagebuilder/get-trigger-dates'
export const DEPRECATE_IMAGE = '/imagebuilder/deprecate-image'
export const GET_AUDIT_LOGS = '/imagebuilder/get-audit-logs'
export const UPDATE_IMAGE = '/imagebuilder/update-image'