/*************************************************
 * Collider
 * @exports
 * @file OnboardClusterSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { onboardClusters, listOnboardingClusters, getOnboardedClusterDetails } from '../../../actions/AccountAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";

const OnboardClusterSidePanel = ({ closeSidePanel, refreshApi, pageMode, selectedAccountDetails, AccountDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        clusters: [],
        callClusterFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.callClusterFilters) {
            setState(prevState => ({ ...prevState, callClusterFilters: false }))
            let params = {};
            params.account_id = AccountDetails?.account_id
            dispatch(listOnboardingClusters(params))
                .then((response) => {
                    if(response) {
                        let clusters = response.results ? response.results : []
                        setState(prevState => ({ ...prevState, clusters }));
                    }
                })
        }
    }, [state.callClusterFilters, dispatch, pageMode, selectedAccountDetails, AccountDetails?.account_id])

    useEffect(() => {
        if(state.callClusterFilters) {
            setState(prevState => ({ ...prevState, callClusterFilters: false }))
            let params = {};
            params.account_id = AccountDetails?.account_id
            dispatch(getOnboardedClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let clusterDetails = response.results ? response.results : []
                        setState(prevState => ({ ...prevState, clusterDetails }));
                    }
                })
        }
    }, [state.callClusterFilters, dispatch, pageMode, selectedAccountDetails, AccountDetails?.account_id])


    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            if(!state.selectedCluster) {
                hasError = true
            }
            // if(pageMode === 'Create') {
            //     if(!state.emailId || !validateEmail(state.emailId)) {
            //         hasError = true
            //     }
            //     params.emailId = state.emailId
            // }
            // params.accounts_info[0].provider = convertToLowerCase(state.selectedCluster)
            params.cluster_name = state.selectedCluster

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(onboardClusters(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Account'

                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'Account Created Successfully'

                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedAccountDetails])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }

            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/4 bg-nero overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <p className='text-white text-xl'>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Onboard")} {pageMode === "View" ? 'Details' : 'Cluster'}</p>
                    <div className='w-3/4 text-md'>
                        <div className='flex flex-wrap'>
                            {AccountDetails && AccountDetails.account_id ?
                                <div className="w-full">
                                    <p className='mb-1 text-backGroundGray min-w-[300px]'>Account&nbsp;Id<span className='text-cyon ml-2'>{AccountDetails.account_id}</span></p>
                                </div>
                            : null}
                            {AccountDetails && AccountDetails.account_name ?
                                <div className="w-full">
                                    <p className='mb-1 text-backGroundGray min-w-[300px]'>Account&nbsp;Name<span className='text-cyon ml-2'>{AccountDetails.account_name}</span></p>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded-md bg-dark3 p-3'>
                        <div className='flex flex-wrap mt-2 pt-2'>
                        <div className='p-3 mb-3' onClick={(event) => handleChildClick(event, 'parent')}>
                            <p className="text-white text-base mt-3">Cluster Options</p>
                            <div className="flex justify-start">
                                <div className="py-1 px-3 w-40 self-center">
                                    <div className="flex mr-0">
                                        <label className="mb-0 ml-4 flex items-center">
                                            <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                name="clusterOption"
                                                id="parallel_cluster"
                                                value="parallel_cluster"
                                                checked={state.clusterOption === "parallel_cluster"}
                                                onChange={(e) => setState(prevState => ({ ...prevState, clusterOption: e.target.value }))}
                                            />
                                            <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                                                Parallel cluster
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="py-1 px-3 w-60 self-center">
                                    <div className="flex mr-2">
                                        <label className="mb-0 ml-4 flex items-center">
                                            <input
                                                className="form-check-input mt-1"
                                                type="radio"
                                                name="clusterOption"
                                                id="slurm_native_cluster"
                                                value="slurm_native_cluster"
                                                checked={state.clusterOption === "slurm_native_cluster"}
                                                onChange={(e) => setState(prevState => ({ ...prevState, clusterOption: e.target.value }))}
                                            />
                                            <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                                                Slurm native cluster
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                           
                        </div>
                        {state.clusterOption === "parallel_cluster" ? (
                            <React.Fragment>
                            <div className='py-1 lg:w-1/3 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Cluster Name</p>
                                    {state.hasError && !state.selectedCluster ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                 <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showCluster")
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.selectedCluster ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedCluster ? state.selectedCluster : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedCluster && state.selectedCluster.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showCluster && pageMode !== 'View' ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                // fields={["application_id", "application_name"]}
                                                options={state.clusters}
                                                selectedValues={state.selectedCluster ? state.selectedCluster : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedCluster: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
                                <div className={`flex justify-end w-full`}>
                                    {state.saveLoading ?
                                        <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2`}>
                                            <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        </button>
                                    :  pageMode !== 'View' ? 
                                        <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2 text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
                                            {pageMode === 'Edit' ? 'Update' : 'Save'}
                                        </button>
                                    : null}
                                    <button className={`btn bg-lightGray px-2 py-1 text-md rounded-md text-white`} onClick={ () => closeSidePanel()}>Cancel</button>
                                </div>
                            </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                    
                </div>
                <div className='m-2'>
                    <div className='rounded-md bg-dark3 p-3'>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Clusters Onboarded</p>
                        {state.clusterDetails && state.clusterDetails.length ?
                        <ResizeableDarkThemeTable
                            columns={[
                                {
                                    Header: 'Resource type',
                                    accessor: 'resource_type',
                                    width: 150,
                                },
                                {
                                    Header: 'Cluster name',
                                    accessor: 'cluster_name',
                                    width: 200,
                                },
                                {
                                    Header: 'Headnode instance Id',
                                    accessor: 'headnode_instance_id',
                                    width: 180,
                                },
                                {
                                    Header: 'Headnode ip',
                                    accessor: 'headnode_ip',
                                    width: 100,
                                },
                                {
                                    Header: 'Headnode status',
                                    accessor: 'headnode_status',
                                    width: 200,
                                },
                                {
                                    Header: 'Proxy instance id',
                                    accessor: 'proxy_instance_id',
                                    width: 200,
                                },
                                {
                                    Header: 'Proxy ip',
                                    accessor: 'proxy_ip',
                                    width: 100,
                                },
                                {
                                    Header: 'Proxy status',
                                    accessor: 'proxy_status',
                                    width: 180,
                                }
                            ]}
                            data={state.clusterDetails}
                            perPage={20}
                            tableHead={'bg-black text-white'}
                            tableBody={'text-black'}
                            tableBodyColor={'bg-black3'}
                            tableType={'table-light-hover'}
                            perPageClass={`bg-lightGray text-white rounded-md ml-2`}
                            textColor={'text-white'}
                            selectedColor={'bg-transparent'}
                            dashboard={true}
                            showPaginationTop={true}
                            paginationColor={'text-white'}
                            sortByColumn={''}
                            riskTooltip={[0]}
                            onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListAccounts: tableRow }))}
                            // tableSize={'table-sm'}
                        />
                    : 
                        <div className='flex justify-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default OnboardClusterSidePanel