import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import { validateMountDir, validateVolumeName } from "../../../../utils/utility";
import { Icon } from "@iconify/react"

const NewStorageSection = (props) => {
  const [state, setState] = useState({
    volumeArray: [0],
  });
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let storageConfiguration = createUpdateCluster?.storage_configuration || [{
      arrayIndex : 0,
    }];
    let volumeArray = [0];
    if (storageConfiguration.length) {
      volumeArray = [];
      storageConfiguration.forEach((row, index) => {
        volumeArray.push(index);
        setState((prevState) => ({
          ...prevState,
          [`storage_type_${index}`]: row.storage_type,
          ...Object.entries(row).reduce((acc, [key, value]) => {
            acc[`${key}_${index}`] = value;
            return acc;
          }, {})
        }));
      });

      setState((prevState) => ({
        ...prevState,
        volumeArray
      }));
    }
  }, [createUpdateCluster]);
  useEffect(() => callPageVist(), [callPageVist]);

  const addSection = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);

      setState((prevState) => ({ ...prevState, [array]: rowList }));
    }
  };

  const removeSection = (array, index, i) => {
    setState((prevState) => ({
      ...prevState,
      ["volume_name_" + index]: "",
      ["snapshot_policy_" + index]: "",
      ["storage_efficiency_" + index]: "",
      ["tiering_policy_name_" + index]: "",
      ["cooling_period_" + index]: "",
      ["volume_security_style_" + index]: "",
      ["volume_size_" + index]: "",
      ["fsx_volume_type_" + index]: ""
    }));

    let rowList = state[array];
    rowList.splice(i, 1);
    setState((prevState) => ({ ...prevState, [array]: rowList }));

    let obj = createUpdateCluster ? createUpdateCluster : {};
    let storageConfiguration =
      obj.storage_configuration && obj.storage_configuration.length
        ? obj.storage_configuration
        : [];
    obj["storage_configuration"] = storageConfiguration.filter(
      (e) => e.arrayIndex !== index
    );
  };
  
  const handleStorageInput = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let storageConfiguration = Array.isArray(obj.storage_configuration) ? obj.storage_configuration : [];
    if (storageConfiguration.length === 0) {
      storageConfiguration[0] = {
        arrayIndex: 0,
        storage_type: props.storageType,
        create: props.storageOption,
      };
    }

    storageConfiguration[0][label] = type === "integer" ? parseInt(value) : value;
    obj["storage_configuration"] = storageConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
    setState((prevState) => ({ ...prevState, [label]: value }));
  };


  const handleStorageVolumeInput = (index, label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let storageConfiguration = obj.storage_configuration && obj.storage_configuration.length ?  obj.storage_configuration : [];

    state.volumeArray.forEach((sIndex) => {
      if (!storageConfiguration.filter((e) => e.arrayIndex === sIndex).length) {
        let dataRow = { 
          arrayIndex: sIndex,
          storage_type : props.storageType,
          create : props.storageOption,
          root_volume_security_style : state.root_volume_security_style,
          storage_capacity : state.storage_capacity,
          throughput_capacity : state.throughput_capacity
        };
        storageConfiguration.push(dataRow);
      }
      let selectedVolumeInput = storageConfiguration.filter(
        (e) => e.arrayIndex === sIndex
      )[0];

      if (sIndex === index) {
        setState((prevState) => ({
          ...prevState,
          [label + "_" + sIndex]: value,
        }));
      }

      let inputValue = sIndex === index ? value : state[label + "_" + sIndex];
      selectedVolumeInput[label] =
        type === "integer" ? parseInt(inputValue) : inputValue;
    });

    obj["storage_configuration"] = storageConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      <React.Fragment>
        {props.storageType === "Efs" ? (
          <React.Fragment>
            <div className={"flex mt-3"}>
              <div className="py-1 w-full">
                <div className="w-1/3">
                  <p className="b-block mb-0">File system name</p>
                  <input
                    type="text"
                    placeholder="Enter File system name (Eg. awuse1nprpc01efsdata)"
                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${
                      props.pageMode !== "Create" ? "disabled text-primary-color" : "text-white"
                    }`}
                    // maxlength='128'
                    value={state["filesystem_name"] ? state["filesystem_name"] : ""}
                    onChange={(e) => {
                      if (props.pageMode === "Create") {
                        handleStorageInput("filesystem_name",validateVolumeName(e.target.value));
                      }
                    }}
                  />
                  {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.volume_name ? (
                    <p className="mt-1 mb-0 text-info">
                      {propsClusterFilters.description.storage_configuration.volume_name}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={"flex mt-3"}>
              <div class="py-1 w-1/3">
                <p className="b-block mb-0">Mount Directory</p>
                <input
                  type="text"
                  placeholder="Enter mount directory (Eg. /data)"
                  className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${
                    props.pageMode !== "Create" ? "disabled text-primary-color" : "text-white"
                  }`}
                  // maxlength='128'
                  value={state["mount_dir"] ? state["mount_dir"] : ""}
                  onChange={(e) => {
                    if (props.pageMode === "Create") {
                      handleStorageInput("mount_dir",validateMountDir(e.target.value));
                    }
                  }}
                />
                {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.mount_dir ? (
                  <p className="mt-1 mb-0 text-info">
                    {propsClusterFilters.description.storage_configuration.mount_dir}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex mt-3">
              <label className="mb-0">
                <input
                  type="checkbox"
                  className="inputdemo mt-1"
                  checked={state["encrytion"] ? true : false}
                  disabled={props.pageMode !== "Create" ? true : false}
                  onChange={() => {
                    if (props.pageMode === "Create") {
                      let value = !state["encrytion"];
                      setState((prevState) => ({...prevState,"encrytion": value}));
                      handleStorageInput("encrytion", value);
                    }
                  }}
                />
              </label>
              <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                Encryption
              </span>
            </div>
            {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.encryption ? (
              <p className="mt-1 mb-0 text-info">
                {propsClusterFilters.description.storage_configuration.encryption}
              </p>
            ) : null}
            <div className={"flex mt-3"}>
              <div className="py-1 w-full">
                <div className="w-1/3">
                  <div className="flex justify-between">
                    <label className="self-center m-0 text-lightGray">
                      Deletion Policy
                    </label>
                    {state.hasError &&
                    !state["deletion_policy"] ? (
                      <small className="text-issues">required</small>
                    ) : null}
                  </div>
                  <div
                    className="border-lightGray bg-transparent rounded-5"
                    onClick={(event) => {
                      if (!state["isDeletionPolicy"]) {
                        event.preventDefault();
                        handleChildClick(event,"child","singleDropDown","isDeletionPolicy");
                      }
                    }}
                  >
                    <p
                      className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                        state["deletion_policy"] &&
                        props.pageMode === "Create" ? "text-white" : "placeholder"
                      }`}
                    >
                      {state["deletion_policy"] ? 
                        propsClusterFilters.deletion_policy && propsClusterFilters.deletion_policy.filter((e) =>
                          e.value === state["deletion_policy"]).length ? 
                            propsClusterFilters.deletion_policy.filter((e) =>
                              e.value === state["deletion_policy"])[0].key
                          : state["deletion_policy"]
                        : "Select"}
                      <Icon icon="icon-park-solid:down-one"className={`${state["deletion_policy"]  ? "text-white" : "text-lightGray"} self-center`}/>
                    </p>
                    {state["isDeletionPolicy"] &&
                    props.pageMode === "Create" ? (
                      <MultiSelectSection
                        fields={["value", "key"]}
                        options={
                          propsClusterFilters && propsClusterFilters.deletion_policy ?
                            propsClusterFilters.deletion_policy.map(
                              (d) => ({key: d, value: d})
                            ) : []
                        }
                        selectedValues={state["deletion_policy"] ? state["deletion_policy"] : ""}
                        callbackMultiSelect={(value) => {
                          if (!value || typeof value === "string") {
                            handleStorageInput("deletion_policy",value);
                            setState((prevState) => ({...prevState, deletion_policy: value}));
                          } else {
                            value.preventDefault();
                            handleChildClick(value,"search","singleDropDown","");
                          }
                        }}
                        singleSelection={true}
                        widthClass={"minWidth220"}
                        removeTopOptions={true}
                      />
                    ) : null}
                  </div>
                </div>
                {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.deletion_policy ? (
                  <p className="mt-1 mb-0 text-info">
                    {propsClusterFilters.description.storage_configuration.deletion_policy}
                  </p>
                ) : null}
              </div>
            </div>
          </React.Fragment>
        ) : props.storageType === "FsxOntap" ? (
          <React.Fragment>
            <React.Fragment>
              <div className={"flex mt-3"}>
                <div className="py-1 w-full">
                  <div className="w-1/3">
                    <div className="flex justify-between">
                      <label className="self-center m-0 text-lightGray">
                        Root Volume Security Style
                      </label>
                      {state.hasError &&
                      !state["root_volume_security_style"] ? (
                        <small className="text-issues">required</small>
                      ) : null}
                    </div>
                    <div
                      className="border-lightGray bg-transparent rounded-5"
                      onClick={(event) => {
                        if (!state["isRootVolumeSecurityStyle"]) {
                          event.preventDefault();
                          handleChildClick(event,"child","singleDropDown","isRootVolumeSecurityStyle");
                        }
                      }}
                    >
                      <p
                        className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                          state["root_volume_security_style"] && props.pageMode === "Create"? "text-white" : "placeholder"
                        }`}
                      >
                        {state["root_volume_security_style"] ? 
                        propsClusterFilters.volume_security_style && propsClusterFilters.volume_security_style.filter((e) =>
                          e.value === state["root_volume_security_style"]).length ? 
                            propsClusterFilters.volume_security_style.filter((e) =>
                              e.value === state["root_volume_security_style"])[0].key
                            : state["root_volume_security_style"]
                          : "Select"}
                        <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                      </p>
                      {state["isRootVolumeSecurityStyle"] &&
                      props.pageMode === "Create" ? (
                        <MultiSelectSection
                          fields={["value", "key"]}
                          options={ propsClusterFilters && propsClusterFilters.volume_security_style ? propsClusterFilters.volume_security_style : []}
                          selectedValues={state["root_volume_security_style"] ? state["root_volume_security_style"]  : ""}
                          callbackMultiSelect={(value) => {
                            if (!value || typeof value === "string") {
                              handleStorageInput("root_volume_security_style", value);
                              setState((prevState) => ({ ...prevState, root_volume_security_style: value}));
                            } else {
                              value.preventDefault();
                              handleChildClick(value,"search","singleDropDown","");
                            }
                          }}
                          singleSelection={true}
                          removeTopOptions={true}
                          widthClass={"minWidth220"}
                        />
                      ) : null}
                    </div>
                  </div>
                  {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.volume_security_style ? (
                    <p className="mt-1 mb-0 text-info">
                      {propsClusterFilters.description.storage_configuration.volume_security_style}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={"flex mt-3"}>
                <div className="py-1 w-full">
                  <div className="w-1/3">
                    <p className="b-block mb-0">Storage Capacity</p>
                    <input
                      type="text"
                      placeholder="Enter storage capacity (in GB)"
                      className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${
                        props.pageMode !== "Create"
                          ? "disabled text-primary-color"
                          : "text-white"
                      }`}
                      // maxlength='128'
                      value={state["storage_capacity"] ? state["storage_capacity"] : ""}
                      onChange={(e) => {
                        if (props.pageMode === "Create") {
                          handleStorageInput("storage_capacity",e.target.value,"integer");
                        }
                      }}
                    />
                  </div>
                  {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.storage_capacity ? (
                    <p className="mt-1 mb-0 text-info">
                      {propsClusterFilters.description.storage_configuration.storage_capacity}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={"flex mt-3"}>
                <div className="py-1 w-full">
                  <div className="w-1/3">
                    <div className="flex justify-between">
                      <label className="self-center m-0 text-lightGray">
                        Throughput Capacity
                      </label>
                      {state.hasError && !state["throughput_capacity"] ? (
                        <small className="text-issues">required</small>
                      ) : null}
                    </div>
                    <div
                      className="border-lightGray bg-transparent rounded-5"
                      onClick={(event) => {
                        if (!state["isThroughputCapacity"]) {
                          event.preventDefault();
                          handleChildClick(event,"child","singleDropDown","isThroughputCapacity");
                        }
                      }}
                    >
                      <p
                        className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                          state["throughput_capacity"] && props.pageMode === "Create" ? "text-white" : "placeholder"
                        }`}
                      >
                        {state["throughput_capacity"] ? 
                        propsClusterFilters.throughput_capacity && propsClusterFilters.throughput_capacity.filter((e) =>
                          e.value === state["throughput_capacity"]).length ? 
                            propsClusterFilters.throughput_capacity.filter((e) => e.value === state["throughput_capacity"])[0].key
                            : state["throughput_capacity"]
                          : "Select"}
                        <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                      </p>
                      {state["isThroughputCapacity"] &&
                      props.pageMode === "Create" ? (
                        <MultiSelectSection
                          fields={["value", "key"]}
                          options={ propsClusterFilters && propsClusterFilters.throughput_capacity ? propsClusterFilters.throughput_capacity.map( (d) => (
                            {key: d, value: d}
                          )) : []
                          }
                          selectedValues={state["throughput_capacity"]  ? state["throughput_capacity"]  : ""}
                          callbackMultiSelect={(value) => {
                            if (!value || typeof value === "string") {
                              handleStorageInput( "throughput_capacity", value, "integer" );
                              setState((prevState) => ({...prevState, throughput_capacity: value,
                              }));
                            } else {
                              value.preventDefault();
                              handleChildClick(value,"search","singleDropDown","");
                            }
                          }}
                          singleSelection={true}
                          removeTopOptions={true}
                          widthClass={"minWidth220"}
                        />
                      ) : null}
                    </div>
                  </div>
                  {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.throughput_capacity ? (
                    <p className="mt-1 mb-0 text-info">
                      {propsClusterFilters.description.storage_configuration.throughput_capacity}
                    </p>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
            <p class="text-white f16 mt-3">Volume options</p>
            <div onClick={(event) => handleChildClick(event, "parent")}>
              {state.volumeArray && state.volumeArray.map((index, j) => {
                  return (
                    <div className="bg-dark3 p-3 rounded mt-3">
                      <div className={"flex mt-3"}>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <p className="b-block mb-0">Volume name</p>
                            <input
                              type="text"
                              placeholder="Enter volume name (Eg. data)"
                              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-3/4 ${
                                props.pageMode !== "Create" ? "disabled text-primary-color" : "text-white"
                              }`}
                              // maxlength='128'
                              value={state["volume_name_" + index] ? state["volume_name_" + index] : ""}
                              onChange={(e) => {
                                if (props.pageMode === "Create") {
                                  handleStorageVolumeInput(index,"volume_name",validateVolumeName(e.target.value));
                                }
                              }}
                            />
                          </React.Fragment>
                          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.volume_name ? (
                            <p className="mt-1 mb-0 text-info">
                              {propsClusterFilters.description.storage_configuration.volume_name}
                            </p>
                          ) : null}
                        </div>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <div className="flex justify-between">
                              <label className="self-center m-0 text-lightGray">
                                Snapshot Policy
                              </label>
                              {state.hasError && !state["snapshot_policy_" + index] ? (
                                <small className="text-issues">
                                  required
                                </small>
                              ) : null}
                            </div>
                            <div
                              className="border-lightGray bg-transparent rounded-5 w-3/4"
                              onClick={(event) => {
                                if (!state["isSnapshotPolicy_" + index]) {
                                  event.preventDefault();
                                  handleChildClick(event,"child","singleDropDown","isSnapshotPolicy_" + index);
                                }
                              }}
                            >
                              <p
                                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                  state["snapshot_policy_" + index] && props.pageMode === "Create" ? "text-white" : "placeholder"
                                }`}
                              >
                                {state["snapshot_policy_" + index] ? 
                                  propsClusterFilters.snapshot_policy && propsClusterFilters.snapshot_policy.filter((e) =>
                                    e.value === state["snapshot_policy_" + index]).length ? 
                                      propsClusterFilters.snapshot_policy.filter((e) =>
                                        e.value === state["snapshot_policy_" + index ])[0].key
                                      : state["snapshot_policy_" + index]
                                  : "Select"}
                                <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                              </p>
                              {state["isSnapshotPolicy_" + index] &&
                              props.pageMode === "Create" ? (
                                <MultiSelectSection
                                  fields={["value", "key"]}
                                  options={ propsClusterFilters && propsClusterFilters.snapshot_policy ? propsClusterFilters.snapshot_policy.map( (d) => ({ key: d, value: d}) ) : []}
                                  selectedValues={state["snapshot_policy_" + index] ? state["snapshot_policy_" + index ] : ""}
                                  callbackMultiSelect={(value) => {
                                    if (!value || typeof value === "string") {
                                      handleStorageVolumeInput(index,"snapshot_policy",value);
                                      setState((prevState) => ({...prevState,["snapshot_policy_" + index]: value}));
                                    } else {
                                      value.preventDefault();
                                      handleChildClick(value,"search","singleDropDown","");
                                    }
                                  }}
                                  singleSelection={true}
                                  widthClass={"minWidth220"}
                                  removeTopOptions={true}
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.snapshot_policy ? (
                            <p className="mt-1 mb-0 text-info">
                              {propsClusterFilters.description.storage_configuration.snapshot_policy}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className={"flex mt-3"}>
                        <div class="py-1 pt-4 mr-5 w-1/2">
                          <div className="flex justify-left">
                            <label className="mb-3">
                              <input
                                type="checkbox"
                                className="inputdemo mt-1"
                                checked={state["storage_efficiency_" + index] ? true: false}
                                disabled={props.pageMode !== "Create" ? true : false}
                                onChange={() => {
                                  if (props.pageMode === "Create") {
                                    let value = !state[ "storage_efficiency_" + index ];
                                    setState((prevState) => ({ ...prevState, ["storage_efficiency_" + index]: value}));
                                    handleStorageVolumeInput(index,"storage_efficiency",value);
                                  }
                                }}
                              />
                            </label>
                            <span className="mb-3 text-lightGray f16 ml-2 text-nowrap">
                              Storage Efficiency
                            </span>
                          </div>
                          {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.storage_efficiency ? (
                            <p className="mt-1 mb-0 text-info">
                              {propsClusterFilters.description.storage_configuration.storage_efficiency}
                            </p>
                          ) : null}
                        </div>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <div className="flex justify-between">
                              <label className="self-center m-0 text-lightGray">
                                Tiering Policy Name
                              </label>
                              {state.hasError &&
                              !state["tiering_policy_name_" + index] ? (
                                <small className="text-issues">
                                  required
                                </small>
                              ) : null}
                            </div>
                            <div
                              className="border-lightGray w-3/4 bg-transparent rounded-5"
                              onClick={(event) => {
                                if (!state["isTieringPolicyName_" + index]) {
                                  event.preventDefault();
                                  handleChildClick(event,"child","singleDropDown","isTieringPolicyName_" + index);
                                }
                              }}
                            >
                              <p
                                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                  state["tiering_policy_name_" + index] && props.pageMode === "Create" ? "text-white" : "placeholder"
                                }`}
                              >
                                {state["tiering_policy_name_" + index]
                                  ? propsClusterFilters.tiering_policy_name &&
                                    propsClusterFilters.tiering_policy_name.filter(
                                      (e) =>
                                        e.value ===
                                        state[
                                          "tiering_policy_name_" + index
                                        ]
                                    ).length
                                    ? propsClusterFilters.tiering_policy_name.filter(
                                        (e) =>
                                          e.value ===
                                          state[
                                            "tiering_policy_name_" +
                                              index
                                          ]
                                      )[0].key
                                    : state[
                                        "tiering_policy_name_" + index
                                      ]
                                  : "Select"}
                                <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                              </p>
                              {state["isTieringPolicyName_" + index] &&
                              props.pageMode === "Create" ? (
                                <MultiSelectSection
                                  fields={["value", "key"]}
                                  options={ propsClusterFilters && propsClusterFilters.tiering_policy_name 
                                    ? propsClusterFilters.tiering_policy_name.map((d) => ({ key: d, value: d })
                                    ) : []
                                  }
                                  selectedValues={
                                    state[
                                      "tiering_policy_name_" + index
                                    ]
                                      ? state[
                                          "tiering_policy_name_" + index
                                        ]
                                      : ""
                                  }
                                  callbackMultiSelect={(value) => {
                                    if (
                                      !value ||
                                      typeof value === "string"
                                    ) {
                                      handleStorageVolumeInput(
                                        index,
                                        "tiering_policy_name",
                                        value
                                      );
                                      setState((prevState) => ({
                                        ...prevState,
                                        ["tiering_policy_name_" +
                                        index]: value,
                                      }));
                                    } else {
                                      value.preventDefault();
                                      handleChildClick(
                                        value,
                                        "search",
                                        "singleDropDown",
                                        ""
                                      );
                                    }
                                  }}
                                  singleSelection={true}
                                  widthClass={"minWidth220"}
                                  removeTopOptions={true}
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                          {propsClusterFilters &&
                          propsClusterFilters.description &&
                          propsClusterFilters.description
                            .storage_configuration &&
                          propsClusterFilters.description
                            .storage_configuration
                            .tiering_policy_name ? (
                            <p className="mt-1 mb-0 text-info">
                              {
                                propsClusterFilters.description
                                  .storage_configuration
                                  .tiering_policy_name
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className={"flex mt-3"}>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <p className="b-block mb-0">
                              Tiering Policy Cooling Period
                            </p>
                            <input
                              type="text"
                              placeholder="Enter cooling period (in days)"
                              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-3/4 ${
                                props.pageMode !== "Create"
                                  ? "disabled text-primary-color"
                                  : "text-white"
                              }`}
                              // maxlength='128'
                              value={
                                state["cooling_period_" + index]
                                  ? state["cooling_period_" + index]
                                  : ""
                              }
                              onChange={(e) => {
                                if (props.pageMode === "Create") {
                                  handleStorageVolumeInput(
                                    index,
                                    "cooling_period",
                                    e.target.value,
                                    "integer"
                                  );
                                }
                              }}
                            />
                          </React.Fragment>
                          {propsClusterFilters &&
                          propsClusterFilters.description &&
                          propsClusterFilters.description
                            .storage_configuration &&
                          propsClusterFilters.description
                            .storage_configuration.cooling_period ? (
                            <p className="mt-1 mb-0 text-info">
                              {
                                propsClusterFilters.description
                                  .storage_configuration.cooling_period
                              }
                            </p>
                          ) : null}
                        </div>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <div className="flex justify-between">
                              <label className="self-center m-0 text-lightGray">
                                Volume Security Style
                              </label>
                              {state.hasError &&
                              !state[
                                "volume_security_style_" + index
                              ] ? (
                                <small className="text-issues">
                                  required
                                </small>
                              ) : null}
                            </div>
                            <div
                              className="border-lightGray w-3/4 bg-transparent rounded-5"
                              onClick={(event) => {
                                if (
                                  !state[
                                    "isVolumeSecurityStyle_" + index
                                  ]
                                ) {
                                  event.preventDefault();
                                  handleChildClick(
                                    event,
                                    "child",
                                    "singleDropDown",
                                    "isVolumeSecurityStyle_" + index
                                  );
                                }
                              }}
                            >
                              <p
                                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                  state[
                                    "volume_security_style_" + index
                                  ] && props.pageMode === "Create"
                                    ? "text-white"
                                    : "placeholder"
                                }`}
                              >
                                {state["volume_security_style_" + index]
                                  ? propsClusterFilters.volume_security_style &&
                                    propsClusterFilters.volume_security_style.filter(
                                      (e) =>
                                        e.value ===
                                        state[
                                          "volume_security_style_" +
                                            index
                                        ]
                                    ).length
                                    ? propsClusterFilters.volume_security_style.filter(
                                        (e) =>
                                          e.value ===
                                          state[
                                            "volume_security_style_" +
                                              index
                                          ]
                                      )[0].key
                                    : state[
                                        "volume_security_style_" + index
                                      ]
                                  : "Select"}
                                <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                              </p>
                              {state[
                                "isVolumeSecurityStyle_" + index
                              ] && props.pageMode === "Create" ? (
                                <MultiSelectSection
                                  fields={["value", "key"]}
                                  options={
                                    propsClusterFilters &&
                                    propsClusterFilters.volume_security_style
                                      ? propsClusterFilters.volume_security_style
                                      : []
                                  }
                                  selectedValues={
                                    state[
                                      "volume_security_style_" + index
                                    ]
                                      ? state[
                                          "volume_security_style_" +
                                            index
                                        ]
                                      : ""
                                  }
                                  callbackMultiSelect={(value) => {
                                    if (
                                      !value ||
                                      typeof value === "string"
                                    ) {
                                      handleStorageVolumeInput(
                                        index,
                                        "volume_security_style",
                                        value
                                      );
                                      setState((prevState) => ({
                                        ...prevState,
                                        ["volume_security_style_" +
                                        index]: value,
                                      }));
                                    } else {
                                      value.preventDefault();
                                      handleChildClick(
                                        value,
                                        "search",
                                        "singleDropDown",
                                        ""
                                      );
                                    }
                                  }}
                                  singleSelection={true}
                                  widthClass={"minWidth220"}
                                  removeTopOptions={true}
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                          {propsClusterFilters &&
                          propsClusterFilters.description &&
                          propsClusterFilters.description
                            .storage_configuration &&
                          propsClusterFilters.description
                            .storage_configuration
                            .volume_security_style ? (
                            <p className="mt-1 mb-0 text-info">
                              {
                                propsClusterFilters.description
                                  .storage_configuration
                                  .volume_security_style
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className={"flex mt-3"}>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <p className="b-block mb-0">Volume Size</p>
                            <input
                              type="text"
                              placeholder="Enter storage capacity (in MB)"
                              className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-3/4 ${
                                props.pageMode !== "Create"
                                  ? "disabled text-primary-color"
                                  : "text-white"
                              }`}
                              // maxlength='128'
                              value={
                                state["volume_size_" + index]
                                  ? state["volume_size_" + index]
                                  : ""
                              }
                              onChange={(e) => {
                                if (props.pageMode === "Create") {
                                  handleStorageVolumeInput(
                                    index,
                                    "volume_size",
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </React.Fragment>
                          {propsClusterFilters &&
                          propsClusterFilters.description &&
                          propsClusterFilters.description
                            .storage_configuration &&
                          propsClusterFilters.description
                            .storage_configuration.storage_capacity ? (
                            <p className="mt-1 mb-0 text-info">
                              {
                                propsClusterFilters.description
                                  .storage_configuration
                                  .storage_capacity
                              }
                            </p>
                          ) : null}
                        </div>
                        <div class="py-1 mr-5 w-1/2">
                          <React.Fragment>
                            <div className="flex justify-between">
                              <label className="self-center m-0 text-lightGray">
                                Volume Type
                              </label>
                              {state.hasError &&
                              !state["fsx_volume_type_" + index] ? (
                                <small className="text-issues">
                                  required
                                </small>
                              ) : null}
                            </div>
                            <div
                              className="border-lightGray w-3/4 bg-transparent rounded-5"
                              onClick={(event) => {
                                if (
                                  !state["isFsxVolumeType_" + index]
                                ) {
                                  event.preventDefault();
                                  handleChildClick(
                                    event,
                                    "child",
                                    "singleDropDown",
                                    "isFsxVolumeType_" + index
                                  );
                                }
                              }}
                            >
                              <p
                                className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                                  state["fsx_volume_type_" + index] &&
                                  props.pageMode === "Create"
                                    ? "text-white"
                                    : "placeholder"
                                }`}
                              >
                                {state["fsx_volume_type_" + index]
                                  ? propsClusterFilters.fsx_volume_type &&
                                    propsClusterFilters.fsx_volume_type.filter(
                                      (e) =>
                                        e.value ===
                                        state[
                                          "fsx_volume_type_" + index
                                        ]
                                    ).length
                                    ? propsClusterFilters.fsx_volume_type.filter(
                                        (e) =>
                                          e.value ===
                                          state[
                                            "fsx_volume_type_" + index
                                          ]
                                      )[0].key
                                    : state["fsx_volume_type_" + index]
                                  : "Select"}
                                <Icon icon="icon-park-solid:down-one" className="self-center" width="16" height="16"/>
                              </p>
                              {state["isFsxVolumeType_" + index] &&
                              props.pageMode === "Create" ? (
                                <MultiSelectSection
                                  fields={["value", "key"]}
                                  options={
                                    propsClusterFilters &&
                                    propsClusterFilters.fsx_volume_type
                                      ? propsClusterFilters.fsx_volume_type
                                      : []
                                  }
                                  selectedValues={
                                    state["fsx_volume_type_" + index]
                                      ? state[
                                          "fsx_volume_type_" + index
                                        ]
                                      : ""
                                  }
                                  callbackMultiSelect={(value) => {
                                    if (
                                      !value ||
                                      typeof value === "string"
                                    ) {
                                      handleStorageVolumeInput(
                                        index,
                                        "fsx_volume_type",
                                        value
                                      );
                                      setState((prevState) => ({
                                        ...prevState,
                                        ["fsx_volume_type_" + index]:
                                          value,
                                      }));
                                    } else {
                                      value.preventDefault();
                                      handleChildClick(
                                        value,
                                        "search",
                                        "singleDropDown",
                                        ""
                                      );
                                    }
                                  }}
                                  singleSelection={true}
                                  widthClass={"minWidth220"}
                                  removeTopOptions={true}
                                />
                              ) : null}
                            </div>
                          </React.Fragment>
                          {propsClusterFilters &&
                          propsClusterFilters.description &&
                          propsClusterFilters.description
                            .storage_configuration &&
                          propsClusterFilters.description
                            .storage_configuration.fsx_volume_type ? (
                            <p className="mt-1 mb-0 text-info">
                              {
                                propsClusterFilters.description
                                  .storage_configuration.fsx_volume_type
                              }
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex justify-end">
                        {state.volumeArray.length > 1 ? (
                          <span
                            className={`cursorPointer text-red f16 self-center ml-4`}
                            onClick={() =>
                              removeSection("volumeArray", index, j)
                            }
                          >
                            {" "}
                            Remove
                          </span>
                        ) : null}
                        {state.volumeArray.length - 1 === j ? (
                          <span
                            className={`cursorPointer text-info f16 self-center ml-4`}
                            onClick={() => addSection("volumeArray")}
                          >
                            {" "}
                            Add
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default NewStorageSection;
