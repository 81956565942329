import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { listPackages, buildImage } from '../../../actions/ImagebuilderAction'
 import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
// import LogsTab from './LogsTab';
import _ from 'lodash'
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchWithHiddenInput';

let searchInput = null
const CreateImage = ({ selectedDetails, closeSidePanel, pageMode }) => {
    const clickOut = useRef();
    const fileInputRef = useRef()
    const [state, setState] = useState({
        showLoading: false,
        callListPackages: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    
    // const handleKeyDown = useCallback((event) => {
    //     if (event.key === 'Escape' || event.keyCode === 27) {
    //         closeSidePanel();
    //     }
    // }, [closeSidePanel])
    
    // useEffect(() => {
    //     // Add event listener for keydown
    //     window.addEventListener('keydown', handleKeyDown);
    
    //     // Cleanup event listener on component unmount
    //     return() => {
    //       window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, [handleKeyDown]);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000); 
        }
    }, [state.showLoading])

    useEffect(() => {
        let selectedPackages = selectedDetails && selectedDetails.package_name ? selectedDetails.package_name : []
        selectedPackages.length && selectedPackages.forEach(pac => {
            setState(prevState => ({ ...prevState, [pac]: true }))
        })
        setState(prevState => ({ 
            ...prevState, 
            script: selectedDetails && selectedDetails.script ? selectedDetails.script : '',
            selectedPackages
        }))
    }, [selectedDetails])

    useEffect(() => {
        if(state.callListPackages) {
            setState(prevState => ({ ...prevState, callListPackages: false }))
            let params = {
                // provider: selectedDetails.provider,
                // account_id: selectedDetails.account_id,
                // region: selectedDetails.region
            }
            if(state.searchText) {
                params.search_input = state.searchText
            }
            if(state.token === 'next' && state.packageResponse && state.packageResponse.next_token) {
                params.next_token = state.packageResponse && state.packageResponse.next_token
            } else if(state.token === 'previous' && state.packageResponse && state.packageResponse.previous_token) {
                params.previous_token = state.packageResponse && state.packageResponse.previous_token
            }
            params.size = state.perPage
            dispatch(listPackages(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []
                        let groupPackages = _.groupBy(results, "package_type")

                        let packageTypes = Object.keys(groupPackages)

                        setState(prevState => ({ ...prevState, packageResponse: response, packageTypes, groupPackages, showLoading: false }))
                    }
                })
        }
    }, [dispatch, state.callListPackages, state.searchText, state.packageResponse, state.perPage, state.token])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.packageResponse && state.packageResponse.next_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'next', callListPackages: true }))
        } else if(action === 'previous' && currentPage === 1 && state.packageResponse && state.packageResponse.previous_token) {
            setState(prevState => ({ ...prevState, showLoading: true, token: 'previous', callListPackages: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }
	
	// const handleInputChange = (label, value) => {
    //     if(label === 'packages') {
    //         let selectedPackages = state.selectedPackages ? state.selectedPackages : []

    //         // if(selectedPackages.filter(e => e === value).length) {
    //         //     selectedPackages = selectedPackages.filter(e => e !== value)
    //         // } else {
    //         //     selectedPackages.push(value)
    //         // }

    //         // label = 'selectedPackages'
    //         // value = selectedPackages

    //         selectedPackages.forEach(pac => {
    //             setState(prevState => ({ ...prevState, [pac]: true }))
    //         })
    //     }
    //     setState(prevState => ({ ...prevState, [label]: value }))
	// }

    useEffect(() => {
        if(state.deployBuild) {
            let params = {}
            params.provider = selectedDetails.provider
            params.account_id = selectedDetails.account_id
            params.region = selectedDetails.region
            params.plan_key = selectedDetails.plan_key
            params.ami_id = selectedDetails.ami_id
            params.image_type = selectedDetails.image_type
            params.os = selectedDetails.os
            params.os_version = selectedDetails.os_version

            // params.image_name = state.image_name
            if(state.selected_tags && state.selected_tags.length) {
                params.tags = state.selected_tags
            }
            if(state.script) {
                params.script = state.script
            }

            let hasError = false
            // if(!state.image_name) {
            //     hasError = true
            // }

            let packages = []
            if(!state.package_type || !state.package_type.length) {
                hasError = true
            } else {
                state.package_type.forEach((pac, pacIndex) => {                    
                    if(state['selectedPackages_'+pacIndex] && state['selectedPackages_'+pacIndex].length) {
                        state['selectedPackages_'+pacIndex].forEach((selPac, selPacIndex) => {
                            let pacRow = {}
                            pacRow.package_type = pac
                            pacRow.package_name = selPac
                            //check package version is avilable
                            if(state['selectedVersion_'+pacIndex+'_'+selPacIndex]) {
                                pacRow.version = state['selectedVersion_'+pacIndex+'_'+selPacIndex]
                            } else {                                
                                // hasError = true
                            }
                            packages.push(pacRow)
                        })
                    }
                })
            }

            if(!packages.length) {
                hasError = true
            }
            params.packages = packages
    
            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true, deployBuild: false }))
            
            if(!hasError) {
                dispatch(buildImage(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in deploying image'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Image deployed successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false }))
                        }
                    })
    
            }
        }
    },  [state.deployBuild, closeSidePanel, dispatch, selectedDetails, state])

    const addNewTagValue = () => {
		let selectedTags = state.selected_tags ? state.selected_tags : []

		if(state.tag_key && state.tag_value && state.tag_value.length) {
            let dataRow = {}
            dataRow.key = state.tag_key
            dataRow.value = state.tag_value
            selectedTags.push(dataRow)
		}

		setState(prevState => ({ ...prevState, selected_tags: selectedTags, tag_key: '',  tag_value: '' }))
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.selected_tags && state.selected_tags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selected_tags: filteredResult }))
	}

    

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        setState(prevState => ({ ...prevState, selectedFiles: file, selectedFileDetails: e }))
    };

    // Function to handle file selection and reading
    const handleFileRead = () => {
        // const file = state.selectedFiles; // Get the selected file

        const file = state.selectedFiles; // Get the selected file

        if (file && (file.type === 'application/x-sh' || file.type === 'text/x-sh')) {
            const reader = new FileReader();

            // FileReader's onload event to read the content
            reader.onload = (e) => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setState(prevState => ({ ...prevState, script: e.target.result, isOpen: false })); // Update the state with file content
            };

            reader.readAsText(file); // Read the file content as text
        } else {
            alert('Please upload a valid .sh file');
        }
    };

	const handleChildClick = (event, type, dropdownType, section) => {
        // if (clickOut.current && !clickOut.current.contains(event.target)) {
        //     closeSidePanel()
        // }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='bg-nero w-4/5 overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <div className='text-white'>
                        <p className='text-xl text-white'>Create Image</p>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded bg-dark3 p-3'>
                        <div className='w-full mt-2'>
                            <p className='b-block mb-0 w-full'>
                                Image Name
                                {/* {state.hasError && !state.image_name? 
                                    <span className='text-danger ml-3'>Please enter image name</span>
                                : null} */}
                            </p>
                            <input
                                type='text'
                                placeholder='Enter Image name'
                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 md:w-1/3 w-full text-white pointer-events-non`}
                                value={selectedDetails.image_name ? selectedDetails.image_name : ''}
                                onChange={e => {
                                    // handleInputChange('image_name', e.target.value)
                                }}
                            />
                        </div>
                        
                        <p className='text-white mt-3 mb-1'>Select packages to install in image</p>
                        <div className='p-3 rounded-md bg-nero mt-3'>
                            <div className='w-full mt-2'>
                                <p className='b-block mb-0 w-full'>Select Package Types</p>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', 'showPakageTypes')
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-0 truncate w-1/4 ${state.package_type ? 'text-white' : 'text-lightGray'}`}>
                                        {state.package_type && state.package_type.length ? state.package_type.length+ ' Selected' : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.package_type  ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showPakageTypes ?
                                        <div className='relative'>
                                            <MultiSelectSection
                                                options={state.packageTypes}
                                                selectedValues={state.package_type ? state.package_type : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, package_type: value }))
                                                }}  
                                                singleSelection={false}
                                                hideSearch={false}
                                                topClass={'auto'}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>

                            {state.package_type && state.package_type.length ?
                                <div className='flex flex-wrap gap-2 mt-3'>
                                    {state.package_type.map((pac, pacIndex) => {
                                        return(
                                            <span key={'tag_'+pacIndex} className="flex border border-info rounded-full self-center text-white px-2 py-1">{pac}
                                                <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, package_type: state.package_type.filter(e => e !== pac) }))} />
                                            </span> 
                                        )
                                    })}
                                </div>
                            : null}

                            {state.package_type && state.package_type.length ?
                                state.package_type.map((pac, pacIndex) => {
                                    return(
                                        <React.Fragment>
                                        <div className='mt-3'>
                                            <div className='bg-black rounded-md p-2 mt-2'>
                                                <div className='w-full'>
                                                    <div className='flex justify-between'>
                                                        <p className="flex mb-0 w-full self-center text-info text-md mb-1" onClick={() => setState(prevState => ({ ...prevState, ['hidePackage_'+pacIndex]: !state['hidePackage_'+pacIndex] }))}>
                                                            {pac.toUpperCase()}
                                                            {state.hasError && (!state['selectedPackages_'+pacIndex] || !state['selectedPackages_'+pacIndex].length) ? 
                                                                <span className='text-danger ml-3'>Please selected package</span>
                                                            : null}
                                                        </p>
                                                        <Icon icon={`fa:angle-${state.showPolicySection ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => setState(prevState => ({ ...prevState, ['hidePackage_'+pacIndex]: !state['hidePackage_'+pacIndex] }))} />
                                                    </div>
                                                    {!state['hidePackage_'+pacIndex] ?
                                                        <div className='flex flex-wrap justify-between'>
                                                            <div className='flex border border-lightGray rounded-md md:w-1/2 my-1'>
                                                                <Icon icon={`bitcoin-icons:search-outline`} className={`text-white w-5 self-center`} width={25} height={25} />
                                                                <div className={`w-11/12`}>
                                                                    <input
                                                                        value={state['searchText_'+pacIndex]}
                                                                        onChange={e => {
                                                                            setState(prevState => ({ ...prevState, ['searchText_'+pacIndex]: e.target.value }))
                                                                            clearTimeout(searchInput)
                                                                            if(e.target.value.length > 2) {
                                                                                searchInput = setTimeout(() => { setState(prevState => ({ ...prevState, ['searchText_'+pacIndex]: e.target.value, startRecord: 0, currentPage: 1, totalPages: 1, packages: [], token: '', showLoading: true, callListPackages: true }))}, 1000); 
                                                                            } else if(!e.target.value.length) {
                                                                                setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, packages: [], token: '', showLoading: true, callListPackages: true }))
                                                                            }
                                                                        }}
                                                                        type='text'
                                                                        className={`bg-transparent text-white text-md pl-1 py-1 w-full`}
                                                                        placeholder='Search packages'
                                                                    /> 
                                                                </div> 
                                                            </div>
                                                           
                                                            {/* <div className={`md:w-4/12 w-full`}>
                                                                <Search 
                                                                    data={state.groupPackages[pac] ? state.groupPackages[pac] : []}
                                                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                                                    searchClassName={'px-2'}
                                                                    searchIconColor={'text-white '}
                                                                    searchPlaceHolder={'Search....'}
                                                                    className={'bg-transparent text-white'}
                                                                    widthHeight={25}
                                                                    filteredData={(filterdSearch) => setState(prevState => ({ ...prevState, ['filterdPackages_'+pacIndex]: filterdSearch }))}
                                                                />
                                                            </div> */}
                                                            {(state.packageResponse && state.packageResponse.total) > (state.packages && state.packages.length) ?
                                                                <div className='flex justify-end text-white text-xs mt-2'>
                                                                    <span className='mx-3 self-center'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></Icon></button> 
                                                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? (state.packageResponse && state.packageResponse.previous_token ? 'text-info' : 'pointer-events-none text-lightGray') : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></Icon></button>
                                                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.packageResponse && state.packageResponse.next_token ? 'text-info' : 'text-ligthGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></Icon></button>
                                                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></Icon></button>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    : null}
                                                </div>
                                                {!state['hidePackage_'+pacIndex] ?
                                                    <div className='flex flex-wrap overflow-y-auto' style={{height: '400px'}}>
                                                        <div className='md:w-2/3 w-full'>
                                                            {state.groupPackages[pac] && state.groupPackages[pac].length ?
                                                                <div className='flex flex-wrap'>
                                                                    {(state['filterdPackages_'+pacIndex] ? state['filterdPackages_'+pacIndex] :  state.groupPackages[pac]).map(row => {
                                                                        return(
                                                                            <div key={'pac_'+row.package_name} className='flex self-center mt-3 md:w-1/3 w-full px-2'>
                                                                                <label className="mb-0">
                                                                                    <input type="checkbox" 
                                                                                        className="mt-1" 
                                                                                        checked={state['selectedPackages_'+pacIndex] && state['selectedPackages_'+pacIndex].includes(row.package_name) ? true : false}
                                                                                        onChange={(event) => {
                                                                                            let selectedPackages = state['selectedPackages_'+pacIndex] ? state['selectedPackages_'+pacIndex] : []
                                                                                            if(selectedPackages.includes(row.package_name)) {
                                                                                                selectedPackages.filter(e => e !== row.package_name)
                                                                                            } else {
                                                                                                selectedPackages.push(row.package_name)
                                                                                            }
                                                                                            setState(prevState => ({ ...prevState, ['selectedPackages_'+pacIndex]: selectedPackages }))
                                                                                        }}
                                                                                    />
                                                                                </label>
                                                                                <span className="mb-0 text-white ml-1" onClick={(e) => {
                                                                                    let selectedPackages = state['selectedPackages_'+pacIndex] ? state['selectedPackages_'+pacIndex] : []
                                                                                    if(selectedPackages.includes(row.package_name)) {
                                                                                        selectedPackages.filter(e => e !== row.package_name)
                                                                                    } else {
                                                                                        selectedPackages.push(row.package_name)
                                                                                    }
                                                                                    setState(prevState => ({ ...prevState, ['selectedPackages_'+pacIndex]: selectedPackages }))
                                                                                }}>{row.package_name}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <div className='flex justify-center m-4'>
                                                                    <p>No packages found.</p>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='md:w-1/3 w-full md:pl-3'>
                                                            <p className='w-full text-whtie'>Selected <span className='text-info'>{pac.toUpperCase()}</span> Packages</p>
                                                            {state['selectedPackages_'+pacIndex] && state['selectedPackages_'+pacIndex].length ?
                                                                <div className='bg-darkGray rounded-md p-2 mt-2'>
                                                                    {state['selectedPackages_'+pacIndex].map((selPac, selPacIndex) => {
                                                                        return(
                                                                            <div className='mb-2 flex w-full'>
                                                                                <div key={'sel_pac_'+selPac} className='flex justify-end  self-center mt-3 md:w-1/2 w-full pr-2'>
                                                                                    <span className="mb-0 text-white mr-1" onClick={(e) => {
                                                                                        setState(prevState => ({ ...prevState, ['selectedPackages_'+pacIndex]: state['selectedPackages_'+pacIndex].filter(e => e !== selPac) }))
                                                                                    }}>{selPac}</span>
                                                                                    <label className="mb-0">
                                                                                        <input type="checkbox" 
                                                                                            className="mt-1" 
                                                                                            checked={state['selectedPackages_'+pacIndex] && state['selectedPackages_'+pacIndex].includes(selPac) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                setState(prevState => ({ ...prevState, ['selectedPackages_'+pacIndex]: state['selectedPackages_'+pacIndex].filter(e => e !== selPac) }))
                                                                                            }}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                                <div className='w-1/2 mt-2'>
                                                                                    <p className='b-block mb-0 w-full'>Versions</p>
                                                                                    <div onClick={(event) => {
                                                                                        if(!state['show_version_'+pacIndex+'_'+selPacIndex]) {
                                                                                            event.preventDefault();
                                                                                            handleChildClick(event, 'child', 'version_'+pacIndex+'_'+selPacIndex, 'show_version_'+pacIndex+'_'+selPacIndex)
                                                                                        }
                                                                                    }}>
                                                                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-0 truncate md: w-1/4 w-full ${state['selectedVersion_'+pacIndex+'_'+selPacIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                                                            {state['selectedVersion_'+pacIndex+'_'+selPacIndex] ? state['selectedVersion_'+pacIndex+'_'+selPacIndex] : 'Select'}
                                                                                            <Icon icon="icon-park-solid:down-one" className={`${state['selectedVersion_'+pacIndex+'_'+selPacIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                                        </p>
                                                                                        {state['show_version_'+pacIndex+'_'+selPacIndex] ?
                                                                                            <div className='relative'>
                                                                                                <MultiSelectSection
                                                                                                    options={state.groupPackages && state.groupPackages[pac] && state.groupPackages[pac].filter(e => e.package_name === selPac).length && state.groupPackages[pac].filter(e => e.package_name === selPac)[0].versions ? state.groupPackages[pac].filter(e => e.package_name === selPac)[0].versions : [] }
                                                                                                    selectedValues={state['selectedVersion_'+pacIndex+'_'+selPacIndex] ? state['selectedVersion_'+pacIndex+'_'+selPacIndex] : ''}
                                                                                                    callbackMultiSelect={(value) => {
                                                                                                        if(!value || typeof(value) === 'string' || typeof(value) === 'number') {
                                                                                                            setState(prevState => ({ ...prevState, ['selectedVersion_'+pacIndex+'_'+selPacIndex]: value }))
                                                                                                        } else {
                                                                                                            value.preventDefault()
                                                                                                            handleChildClick(value, "search", 'singleDropDown', "")
                                                                                                        }
                                                                                                    }}
                                                                                                    singleSelection={true}
                                                                                                    widthClass={'minWidth220'}
                                                                                                    className={'right-0'}
                                                                                                    removeTopOptions={true}
                                                                                                />
                                                                                            </div>
                                                                                        : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                        </React.Fragment>
                                    ) 
                                })
                            : null}
                        </div>

                        <div className='mt-3'>
                            {/* <div className='py-1 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">User data script
                                        <Icon icon="fe:star" className='text-purplishRed ml-1 self-center' width="12" height="12" />
                                    </p>
                                </div>
                                <textarea 
                                    id="script"
                                    name="script"
                                    rows="5" 
                                    className="w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 text-white" 
                                    onChange={(e) => setState(prevState => ({ ...prevState, script: e.target.value }))}
                                    value={state.script}
                                />
                            </div> */}
                            <div className='py-1 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray flex">User data script</p>
                                </div>
                                <div className="file-reader-textbox border border-lightGray rounded-md">
                                    <textarea className="form-control w-full p-2 bg-transparent text-white" rows="4" name="script" value={state.script}></textarea>
                                    <div className="upload">
                                        <p className='text-white'>(or) upload file in sh format</p>
                                        <label className="px-0">
                                            <button type="button" className="btn bg-info text-white rounded-md px-4" onClick={() => {
                                                setState(prevState => ({ ...prevState, isOpen: true }))
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = '';
                                                }
                                            }}>Upload</button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='flex'>
                            <div className='py-3 w-full'>
                                <p className='b-block mb-0'>Tags</p>
                                <div className='flex flex-wrap gap-2 mb-1'>
                                    <input
                                        type='text'
                                        placeholder='Enter Key'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white lg:w-1/3 md:w-1/2 w-full`}
                                        // maxlength='128'
                                        value={state.tag_key ? state.tag_key : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, tag_key: e.target.value }))}
                                    />
                                    <input
                                        type='text'
                                        placeholder='Enter value'
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white lg:w-1/3 md:w-1/2 w-full`}
                                        // maxlength='128'
                                        value={state.tag_value ? state.tag_value : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, tag_value: e.target.value }))}
                                    />
                                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 lg:mt-2 md:mt-2 mt-0 cursor-pointer' onClick={() => addNewTagValue()}/>
                                </div>
                                {state.selected_tags && state.selected_tags.length ? 
                                    <div className='flex flex-wrap'>
                                        {state.selected_tags.map((tag, tIndex) => {
                                            return(
                                                <span key={'tag_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'tag_'+tIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {tag.key +' : '+tag.value}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                            </div>
                        </div>
                        
                        <div className='my-3 flex justify-end mx-2'>
                            {state.saveLoading ?
                                <button type="button" className="bg-green text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center">
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                    Deploying
                                </button>
                            :
                                <div className='flex'>
                                    <button type="button" className="bg-green text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center" onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, deployBuild: true }))}>Deploy</button>
                                    <button type="button" className="bg-lightGray text-white ml-2 font-bold px-2 py-1 rounded-md inline-flex items-center self-center" onClick={ () => closeSidePanel()}>Cancel</button>
                                </div>
                            }
                        </div>
                        {/* <p className='text-white mt-3'>Console</p>
                        <LogsTab
                            selectedDetails={selectedDetails}
                            backGroundColor={'bg-black'}
                        /> */}
                        
                    </div>
                </div>
            </div>
            <div id="popup-modal" tabIndex="-1" className={`flex ${state.isOpen ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => {
                            setState(prevState => ({ ...prevState, isOpen: false, selectedFiles: '' }))
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        }}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">File Upload</h3>
                            <React.Fragment>
                            <input type="file" onChange={handleFileChange} ref={fileInputRef} />
                            {/* {selectedFiles.length > 0 && (
                                <div className="mt-3">
                                    <p>Selected Files:</p>
                                    <ul>
                                    {selectedFiles.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                    ))}
                                    </ul>
                                </div>
                            )} */}
                            </React.Fragment>

                            <div className='flex justify-end mt-4'>
                                
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" 
                                    onClick={handleFileRead}
                                >
                                    {state.uploadProgress ?
                                    <div className='flex'>
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                        Uploading
                                    </div>
                                    :
                                    'Upload File'
                                    }
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-red text-white" onClick={() => {
                                    setState(prevState => ({ ...prevState, isOpen: false, selectedFiles: '' }))
                                    if (fileInputRef.current) {
                                        fileInputRef.current.value = '';
                                    }
                                }}>
                                    cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateImage;