/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Recommendations.js
 * @author Prakash // on 10/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import RecommendationsLeftSection from './RecommendationsLeftSection'
import RecommendationsRightSection from './RecommendationsRightSection'

const Recommendations = () => {
    return (
        <div className={`overflow-auto flex-grow-1 px-0`}>
            <div className='flex flex-wrap h-100'>
                <div className={`border-r border-lightGray lg:w-5/12 w-full p-0 h-100`}>
                    <RecommendationsLeftSection />
                </div>
                <div className={`lg:w-7/12 w-full px-2 h-100`}>
                    <RecommendationsRightSection />
                </div>
            </div>
        </div>
    )
}

export default Recommendations