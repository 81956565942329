/*************************************************
 * Collider
 * @exports
 * @file ImageBuilderAction.js
 * @author Prakash // on 08/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../components/ApiCalls'
import {
    IMAGE_BUILDER_END_POINT,
	LIST_IMAGES,
	LIST_BUILDS,
	GET_BUILD_LOGS,
	LIST_REPORTS,
	GET_IMAGE_FILTERS,
	SUBSCRIBE_IMAGE,
	DOWNLOAD_REPORT,
	BUILD_IMAGE,
	LIST_PACKAGES,
	LIST_NOTIFICATIONS,
	SHARE_IMAGE,
	LIST_PLANS,
	BUILD_PLAN,
	SCAN_IMAGE,
	LIST_SCHEDULES,
	CREATE_SCHEDULE,
	DELETE_SCHEDULE,
	GET_TRIGGER_DATES,
	DEPRECATE_IMAGE,
	GET_AUDIT_LOGS,
	UPDATE_IMAGE
} from '../config'

/**
 * Action to list-images
 * @param {Function} callback
 */
export const listImages = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_IMAGES;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-builds
 * @param {Function} callback
 */
export const listBuilds = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_BUILDS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to get-build-logs
 * @param {Function} callback
 */
export const getBuildLogs = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+GET_BUILD_LOGS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-reports
 * @param {Function} callback
 */
export const listReports = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_REPORTS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to get-image-filters
 * @param {Function} callback
 */
export const getImageFilters = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+GET_IMAGE_FILTERS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to subscribe-image
 * @param {Function} callback
 */
export const subscribeImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+SUBSCRIBE_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to dowbload-report
 * @param {Function} callback
 */
export const downloadReport = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+DOWNLOAD_REPORT;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to build-image
 * @param {Function} callback
 */
export const buildImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+BUILD_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-packages
 * @param {Function} callback
 */
export const listPackages = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_PACKAGES;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-notifications
 * @param {Function} callback
 */
export const listNotifications = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_NOTIFICATIONS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to share-image
 * @param {Function} callback
 */
export const shareImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+SHARE_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-plans
 * @param {Function} callback
 */
export const listPlans = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_PLANS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to build-plan
 * @param {Function} callback
 */
export const buildPlan = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+BUILD_PLAN;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to scan-image
 * @param {Function} callback
 */
export const scanImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+SCAN_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to list-schedules
 * @param {Function} callback
 */
export const listSchedules = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+LIST_SCHEDULES;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to create-schedule
 * @param {Function} callback
 */
export const createSchedule = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+CREATE_SCHEDULE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to delete-schedule
 * @param {Function} callback
 */
export const deleteSchedule = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+DELETE_SCHEDULE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to get-trigger-dates
 * @param {Function} callback
 */
export const getTriggerDates = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+GET_TRIGGER_DATES;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to deprecate-image
 * @param {Function} callback
 */
export const deprecateImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+DEPRECATE_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to get-audit-logs
 * @param {Function} callback
 */
export const getAuditLogs = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+GET_AUDIT_LOGS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};

/**
 * Action to update-image
 * @param {Function} callback
 */
export const updateImage = (params) => {
	return async () => {
		try {
			const url = IMAGE_BUILDER_END_POINT+UPDATE_IMAGE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
};