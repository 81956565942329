import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getUpdates } from '../../../actions/Collider/HpcAction'
import ClusterDetails from './ClusterDetails';
import QueueDetails from './QueueDetails';
import UserDetails from './UserDetails';
import { momentDateFormat, momentDateGivenFormat } from '../../../utils/utility';
import ProjectDetails from './ProjectDetails';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedType: 'cluster_name',
        selectedViewType: 'cluster_name',
        showPageLoading: true,
        selectedDuration: 'currentMonth',
        topSectionFilters: ['provider', 'account', 'region', 'cluster', 'type', 'duration'],
        showUpdateSection: true,

        startUpcoming: 0, 
        currentUpcoming: 1,
        totalUpcoming: 1,
        perPage: 5,

        startLog: 0, 
        currentLogPage: 1,
        totalLogPages: 1,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        dispatch(getUpdates({}))
            .then((response) => {
                if(response) {
                    let upcomingUpdates = response.upcoming_updates ? response.upcoming_updates : []
                    // let upcomingUpdates = state.response.upcoming_updates ? state.response.upcoming_updates : []
                    let totalUpcoming = Math.ceil(upcomingUpdates.length/state.perPage)

                    let updateLogs = response.update_logs ? response.update_logs : []
                    // let updateLogs = state.response.update_logs ? state.response.update_logs : []
                    let totalLogPages = Math.ceil(updateLogs.length/state.perPage)

                    setState(prevState => ({ ...prevState, updateResponse: response, upcomingUpdates, totalUpcoming, updateLogs, totalLogPages }))
                }
            })
    }, [dispatch, state.perPage, state.response])
    
    useEffect(() => {        
        if(state.showLoading) {
            setTimeout(() => {
                setState(prevState => ({ ...prevState, showLoading: false }))
            }, 5000);
        }
    }, [state.showLoading]); // Empty dependency array ensures this runs only once on mount   

    // useEffect(() => {
    //     if(providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '') }));
    //     }
    // }, [providers])

    useEffect(() => {
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } 
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

	}, [state.selectedDuration])

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedType: filter.selectedType ? filter.selectedType : '', 
            selectedViewType: filter.selectedType ? filter.selectedType : '', 
            selectedProject: filter.selectedProject ? filter.selectedProject : [],
            selectedProjectTags: filter.selectedProjectTags ? filter.selectedProjectTags : [],
            selectedUser: filter.selectedUser ? filter.selectedUser : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            showPageLoading: false,
            callSearch: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedUser: [], selectedProject: '', selectedProjectTags : [], selectedDuration: 'currentMonth', datePickerStartDate: momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00'), datePickerEndDate: momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59'), showPageLoading: false, callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showPageLoading: true }))
        }

    }, [state.callSearch])

    const navigateUpcomingPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startUpcoming
        if(action === 'next' && currentPage !== state.totalUpcoming) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentUpcoming: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentUpcoming: state.totalUpcoming }));
            startRecord = (state.totalUpcoming - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startUpcoming: startRecord }))
    }

    const navigateLogPage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startLog
        if(action === 'next' && currentPage !== state.totalLogPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentLogPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentLogPage: state.totalLogPages }));
            startRecord = (state.totalLogPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startLog: startRecord }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    };

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Dashboard</p>
                        <p className="mx-4 text-white">Consolidated view of HPC resource consumption</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='p-2 grow overflow-auto'>
                <div className='mt-2'>
                    <div className='bg-dark2 p-3 rounded mb-2'>
                        <div className={`flex justify-${!state.showUpdateSection ? 'between' : 'end'}`}>
                            {!state.showUpdateSection ? 
                                <p className={`text-white mb-0`}>Upcoming Updates</p>
                            : null}
                            <Icon icon={`fa:angle-${state.showUpdateSection ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, showUpdateSection: !state.showUpdateSection }))}/>
                            {/* <span className={`ml-4 fa fa-angle-${state.showUpdateSection ? 'up' : 'down'} f22 text-lightGray cursor-pointer`}></span> */}
                        </div>
                        {state.showUpdateSection ?
                            <div className="flex justify-between mt-2">
                                <div className="lg:w-1/2 lg:pr-2 w-full">
                                    <div className="bg-black rounded p-3 h-full">
                                        <div className="flex justify-between">
                                            <p className="mb-0 text-white">Upcoming Updates</p>
                                            {state.upcomingUpdates && state.upcomingUpdates.length > state.perPage ?
                                                <div className="pagination text-sm">
                                                    <span className="mx-3">Page <strong>{state.currentUpcoming} of {state.totalUpcoming}</strong></span>
                                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentUpcoming === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateUpcomingPage('start', state.currentUpcoming)} /></button> 
                                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentUpcoming === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`}  onClick={() => navigateUpcomingPage('previous', state.currentUpcoming)} /></button> 
                                                    {/* <span>
                                                        Go to
                                                        <input
                                                            type='number'
                                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                            onChange={e => {
                                                                const page = e.target.value ? Number(e.target.value) : ""
                                                                gotoPage(page)
                                                            }}
                                                            className="ml-1 inputClass"
                                                        />
                                                    </span> */}
                                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentUpcoming === state.totalUpcoming ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigateUpcomingPage('next', state.currentUpcoming)}/></button> 
                                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentUpcoming === state.totalUpcoming ? 'pointer-events-none text-ligthGray' : 'text-info'}`} onClick={() => navigateUpcomingPage('end', state.currentUpcoming)} /></button>
                                                </div>
                                            : null}
                                        </div>
                                        {state.upcomingUpdates && state.upcomingUpdates.length ?
                                            state.upcomingUpdates.slice(state.startUpcoming, state.startUpcoming + state.perPage).map((row, rIndex )=> {
                                                return(
                                                    <div key={'updates_'+rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-mediumDarkGray': ''}`}>
                                                        <div className="flex mr-2 px-2 mb-2 pb-1 w-full">
                                                            <ul className="mb-0 justify-end pl-2">
                                                                <li className="w-full mb-0 mr-2 mb-1 text-white font-bold">
                                                                    {row.date ? 
                                                                        <span className='text-purple-2 mr-2'>Date: {momentDateFormat(row.date, 'DD MMM YYYY HH:mm')} (utc time)</span>
                                                                    : null}
                                                                    {row.message}
                                                                </li>
                                                                {/* <li className="displayInlieBlock w-100 mb-0 mr-2 mb-1 text-purple-2 font-weight-bold">Date: {row.date ? momentDateFormat(row.date, 'DD MMM YYYY HH:mm') : null} (utc time)</li> */}
                                                                <li className="inline-block w-full mb-0 mb-1 text-white">{row.description ? row.description : ''}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                <div className="lg:w-1/2 lg:mt-0 w-full mt-2">
                                    <div className="bg-black rounded p-3 h-full">
                                        <div className="flex justify-between">
                                            <p className="mb-0 text-white">Update Logs</p>
                                            {state.updateLogs && state.updateLogs.length > state.perPage ?
                                                <div className="pagination text-sm">
                                                    <span className="mx-3">Page <strong>{state.currentUpcoming} of {state.totalUpcoming}</strong></span>
                                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentLogPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('start', state.currentLogPage)} /></button> 
                                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentLogPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('previous', state.currentLogPage)} /></button> 
                                                    {/* <span>
                                                        Go to
                                                        <input
                                                            type='number'
                                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                            onChange={e => {
                                                                const page = e.target.value ? Number(e.target.value) : ""
                                                                gotoPage(page)
                                                            }}
                                                            className="ml-1 inputClass"
                                                        />
                                                    </span> */}
                                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentLogPage === state.totalLogPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('next', state.currentLogPage)}/></button> 
                                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentLogPage === state.totalLogPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateLogPage('end', state.currentLogPage)} /></button>
                                                </div>
                                            : null}
                                        </div>
                                        {state.updateLogs && state.updateLogs.length ?
                                            state.updateLogs.slice(state.startLog, state.startLog + state.perPage).map((row, rIndex)=> {
                                                return(
                                                    <div key={'logs_'+rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-mediumDarkGray': ''}`}>
                                                        <div className="flex mr-2 px-2 mb-2 pb-1 w-full">
                                                            <ul className="mb-0 justify-end pl-2">
                                                                <li className="w-full mb-0 mr-2 mb-1 text-white font-bold">
                                                                {row.date ? 
                                                                    <span className='text-purple-2 mr-2'>Date: {momentDateFormat(row.date, 'DD MMM YYYY HH:mm')} (utc time)</span>
                                                                : null}
                                                                {row.message}</li>
                                                                {/* <li className={`displayInlieBlock w-100 mb-0 mr-2 mb-1 text-white`}>{row.description ? row.description : ''}</li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                    {state.showPageLoading && state.selectedProvider && state.datePickerStartDate ? 
                        state.selectedViewType === 'cluster_name' ?
                            <ClusterDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'project' ?
                            <ProjectDetails 
                                selectedProvider={state.selectedProvider}
                                selectedProject={state.selectedProject}
                                selectedProjectTags={state.selectedProjectTags}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'queue_name' ?
                            <QueueDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'user' ?
                            <UserDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedUser={state.selectedUser}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : null
                    : null}
                </div>
            </div>
        </div>
    );
};

export default LandingPage