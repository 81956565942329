import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import MultiSelectSection from '../../../common/MultiSelectSection';
import { validateAmiId, validateClusterName } from "../../../../utils/utility";
import {Icon} from "@iconify/react";

const ClusterSection = (props) => {
    const [state, setState] = useState({});
    const createUpdateCluster = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
    );
    const propsClusterFilters = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
    );
  
    const dispatch = useDispatch(); // Create a dispatch function

    const callPageVist = useCallback(() => {
      let clusterConfiguration = createUpdateCluster && createUpdateCluster.cluster_configuration
          ? createUpdateCluster.cluster_configuration
          : {};
  
      Object.entries(clusterConfiguration).forEach(([key, value]) => {
        let clusterFields = [
          "os",
          "custom_ami",
          "cloudwatch_logs_deletion_policy",
          "disable_simultaneous_multitreading",
          "detailed_monitoring",
          "cloudwatch_logs_retention_in_days",
          "cloudwatch_logs_enabled",
          "cloudwatch_dashboard_enabled"
        ];
        if (key === "os" && value) {
          setState((prevState) => ({ ...prevState, operatingSystem: true }));
        } else if (key === "custom_ami" && value) {
          setState((prevState) => ({ ...prevState, customAmi: true }));
        }
        if (clusterFields.includes(key)) {
          setState((prevState) => ({ ...prevState, [key]: value }));
        }
      });
  
      let selected_tags =
        createUpdateCluster &&
        createUpdateCluster.tags &&
        createUpdateCluster.tags.length
          ? createUpdateCluster.tags
          : [];
  
      setState((prevState) => ({ ...prevState, selected_tags }));
    }, [createUpdateCluster]);
  
    useEffect(() => callPageVist(), [callPageVist]);
  
    const addNewTagValue = () => {
      let selectedTags = state.selected_tags ? state.selected_tags : [];
  
      if (state.tag_key && state.tag_value && state.tag_value.length) {
        let dataRow = {};
        dataRow.key = state.tag_key;
        dataRow.value = state.tag_value;
        selectedTags.push(dataRow);
      }
  
      setState((prevState) => ({
        ...prevState,
        selected_tags: selectedTags,
        tag_key: "",
        tag_value: "",
      }));
  
      handleInputChange("tags", selectedTags);
    };
  
    const removeTagSection = (tag) => {
      let filteredResult =
        state.selected_tags && state.selected_tags.filter((e) => e !== tag);
      setState((prevState) => ({ ...prevState, selected_tags: filteredResult }));
  
      handleInputChange("tags", filteredResult);
    };
  
    const handleInputChange = (label, value) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      obj[label] = value;
      if (label === "provider") {
        obj["account_id"] = "";
        obj["region"] = "";
      }
  
      if (label === "custom_ami") {
        delete obj.os;
      } else if (label === "os") {
        delete obj.custom_ami;
      }
      dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  
      setState((prevState) => ({ ...prevState, [label]: value }));
    };
  
    const handleConfigurationInputChange = (label, value, type) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let clusterConfiguration = obj.cluster_configuration
        ? obj.cluster_configuration
        : {};
      clusterConfiguration[label] = value;
      obj["cluster_configuration"] = clusterConfiguration
      dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  
      clusterConfiguration[label] = type === "integer" ? parseInt(value) : value;
      setState((prevState) => ({ ...prevState, [label]: value }));
    };
  
    const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='rounded bg-dark p-3 mt-3'>
                <div className='flex mt-3'>
                    <div className='py-1 w-full'>
                        <div className='lg:w-1/3 md:w-1/2 w-full'>
                            <p className='b-block mb-0'>Cluster name</p>
                            <input
                                type='text'
                                placeholder='Enter Cluster name'
                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${props.pageMode !== 'Create' ? 'disabled text-lightGray' : 'text-white'} focus-visible:border-lightGray`}
                                // maxlength='128'
                                value={createUpdateCluster && createUpdateCluster.cluster_name ? createUpdateCluster.cluster_name : ''}
                                onChange={e => {
                                    if(props.pageMode === 'Create') {
                                        setState(prevState => ({ ...prevState, cluster_name:  validateClusterName(e.target.value)}))
                                        handleInputChange('cluster_name',  validateClusterName(e.target.value))
                                    }
                                }}
                            />
                        </div>
                        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.cluster_name ?
                            <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.cluster_name}</p>
                        : null}
                    </div>
                </div>
                <div className='flex mt-3'>
                    <div className='py-1 w-2/3'>
                        <div className='lg:w-1/3 md:w-1/2 w-full'>
                            {/* <div className='flex justify-between'>
                                <label className='self-center m-0 text-lightGray text-nowrap'>Request certificate</label>
                                {state.hasError && state.os ? 
                                    <small className='text-issues'>required</small>
                                : null}
                            </div> */}
                            <p className='b-block mb-0'>Operating System</p>
                            {state.hasError && state.os ? 
                                <small className='text-issues'>required</small>
                            : null}
                            <div className='border-lightGray bg-transparent rounded-5'
                                onClick={(event) => {
                                    if(!state.showOs) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "showOs")
                                    }
                                }}
                            >
                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.os && props.pageMode === 'Create' ? 'text-white' : 'placeholder '}`}>
                                    {state.os ? (propsClusterFilters && propsClusterFilters.os && propsClusterFilters.os.filter(e => e.value === state.os).length ? propsClusterFilters.os.filter(e => e.value === state.os)[0].key : state.os) : 'Select'}
                                    {props.pageMode === 'Create' ? 
                                        <Icon icon="icon-park-solid:down-one" className={`${state.os ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    : null}
                                </p>
                                {state.showOs && props.pageMode === 'Create' ?
                                    <MultiSelectSection
                                        fields={["value", "key"]}
                                        options={propsClusterFilters && propsClusterFilters.os ? propsClusterFilters.os : []}
                                        selectedValues={state.os ? state.os : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState,  os: value }))
                                                handleConfigurationInputChange('os', value)
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                        widthClass={'minWidth220'}
                                        removeTopOptions={true}
                                    />
                                : null}
                            </div>
                        </div>
                        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.cluster_configuration && propsClusterFilters.description.cluster_configuration.os ?
                            <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.cluster_configuration.os}</p>
                        : null}
                    </div>
                </div>
                <div className='flex mt-3'>
                    <div className='py-1'>
                        <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <p className='b-block mb-0'>Custom AMI</p>
                            <input
                                type='text'
                                placeholder='Enter custom ami'
                                className='bg-transparent border border-lightGray rounded-md px-2 py-1 text-white w-full'
                                // maxlength='128'
                                disabled={props.pageMode !== 'Create' ? true : false}
                                value={state.custom_ami ? state.custom_ami : ''}
                                onChange={e => {
                                    if(props.pageMode === 'Create') {
                                        setState(prevState => ({ ...prevState, custom_ami:  validateAmiId(e.target.value)}))
                                        handleConfigurationInputChange('custom_ami',  validateAmiId(e.target.value))
                                    }
                                }}
                            />
                        </div>
                        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.cluster_name ?
                            <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.cluster_name}</p>
                        : null}
                    </div>
                </div>
                <div className='flex mt-3'>
                    <div className='py-1 w-full self-center'>
                        <div className='flex'>
                            <label className="mb-0">
                                <input type="checkbox" 
                                    className="mt-1" 
                                    checked={state.disable_simultaneous_multitreading ? true : false}
                                    disabled={props.selectedData && props.selectedData.cluster_configuration && props.selectedData.cluster_configuration.disable_simultaneous_multitreading ? true : false}                                    
                                    onChange={(event) => {
                                        if(props.pageMode === 'Create' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.disable_simultaneous_multitreading)) {
                                            let disable_simultaneous_multitreading = !state.disable_simultaneous_multitreading
                                            setState(prevState => ({ ...prevState, disable_simultaneous_multitreading: !state.disable_simultaneous_multitreading}))
                                            handleConfigurationInputChange("disable_simultaneous_multitreading", disable_simultaneous_multitreading)
                                        }
                                    }}
                                />
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2">Disable simultaneous multitreading</span>
                        </div>
                    </div>
                </div>
                
                <p className='text-white f16 mt-3'>Monitoring</p>
                <div className='bg-dark3 p-3 rounded mt-3'>
                    <div className={'flex'}>
                        <div className='py-1 w-full self-center'>
                            <div className='flex'>
                                <label className="mb-0">
                                    <input type="checkbox" 
                                        className="mt-1" 
                                        checked={state.detailed_monitoring ? true : false}
                                        // disabled={props.selectedData && props.selectedData.cluster_configuration && props.selectedData.cluster_configuration.detailed_monitoring ? true : false}
                                        onChange={(event) => {
                                            if(props.pageMode === 'Create' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.detailed_monitoring)) {
                                                let detailed_monitoring = !state.detailed_monitoring
                                                setState(prevState => ({ ...prevState, detailed_monitoring: !state.detailed_monitoring}))
                                                handleConfigurationInputChange("detailed_monitoring", detailed_monitoring)
                                            }
                                        }}
                                    />
                                </label>
                                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Enable detailed monitoring</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className='py-1 w-full self-center'>
                            <div className='flex'>
                                <label className="mb-0">
                                    <input type="checkbox" 
                                        className="mt-1"                                         
                                        checked={state.cloudwatch_dashboard_enabled ? true : false}
                                        // disabled={props.selectedData && props.selectedData.cluster_configuration && props.selectedData.cluster_configuration.cloudwatch_dashboard_enabled ? true : false}
                                        onChange={(event) => {
                                            if(props.pageMode === 'Create' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.cloudwatch_dashboard_enabled)) {
                                                let cloudwatch_dashboard_enabled = !state.cloudwatch_dashboard_enabled
                                                setState(prevState => ({ ...prevState, cloudwatch_dashboard_enabled: !state.cloudwatch_dashboard_enabled}))
                                                handleConfigurationInputChange("cloudwatch_dashboard_enabled", cloudwatch_dashboard_enabled)
                                            }
                                        }}
                                    />
                                </label>
                                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Enable cloudwatch dashboard</span>
                            </div>
                        </div>
                    </div>
                    <div className={'flex'}>
                        <div className='py-1 w-full self-center'>
                            <div className='flex'>
                                <label className="mb-0">
                                    <input type="checkbox" 
                                        className="mt-1" 
                                        // disabled={props.selectedData && props.selectedData.cluster_configuration && props.selectedData.cluster_configuration.cloudwatch_logs_enabled ? true : false}
                                        checked={state.cloudwatch_logs_enabled ? true : false}
                                        onChange={(event) => {
                                            if(props.pageMode === 'Create' || (props.selectedData && props.selectedData.cluster_configuration && !props.selectedData.cluster_configuration.cloudwatch_logs_enabled)) {
                                                let cloudwatch_logs_enabled = !state.cloudwatch_logs_enabled
                                                // setState(prevState => ({ ...prevState, cloudwatch_logs_enabled: !state.cloudwatch_logs_enabled}))
                                                handleConfigurationInputChange("cloudwatch_logs_enabled", cloudwatch_logs_enabled)
                                            }
                                        }}
                                    />
                                </label>
                                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">Enable cloudwatch logs</span>
                            </div>
                        </div>
                    </div>
                    {state.cloudwatch_logs_enabled ?
                        <div className={'flex mt-2 pt-2'}>
                            <div className='py-1 lg:w-1/5 md:1/3 w-full'>
                                <div className='flex justify-between'>
                                    <label className='self-center m-0 text-lightGray'>Deletion policy</label>
                                    {state.hasError && state.cloudwatch_logs_deletion_policy ? 
                                        <small className='text-issues'>required</small>
                                    : null}
                                </div>
                                <div className='border-lightGray bg-transparent rounded-5'
                                    onClick={(event) => {
                                        if(!state.showDeletionPolicy) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showDeletionPolicy")
                                        }
                                    }}
                                >
                                    <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.cloudwatch_logs_deletion_policy ? 'text-white truncate' : 'placeholder'}`}>
                                        {state.cloudwatch_logs_deletion_policy ? state.cloudwatch_logs_deletion_policy : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.cloudwatch_logs_deletion_policy ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showDeletionPolicy && props.pageMode !== 'edit' ?
                                        <MultiSelectSection
                                            // fields={["value", "key"]}
                                            options={propsClusterFilters && propsClusterFilters.deletion_policy ? propsClusterFilters.deletion_policy : []}
                                            selectedValues={state.deletion_policy ? state.deletion_policy : ""}
                                            callbackMultiSelect={(value) => {
                                                if(!value || typeof(value) === 'string') {
                                                    setState(prevState => ({ ...prevState, ldap_tls_request_certificate: value }))
                                                    handleConfigurationInputChange('cloudwatch_logs_deletion_policy', value)
                                                } else {
                                                    value.preventDefault()
                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                }
                                            }}
                                            singleSelection={true}
                                            widthClass={'minWidth220'}
                                            removeTopOptions={true}
                                        />
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/5 md:1/3 w-full ml-5'>
                                <div className='flex justify-between'>
                                    <label className='self-center m-0 text-lightGray'>Retention in days</label>
                                    {state.hasError && !state.cloudwatch_logs_retention_in_days ? (
                                        <small className='text-issues'>required</small>
                                    ) : null}
                                </div>
                                {props.pageMode !== "edit" ? (
                                    <div
                                        className="border-lightGray bg-transparent rounded-5"
                                        onClick={(event) => {
                                            if (!state["isLogsRetentionDays"]) {
                                                event.preventDefault();
                                                handleChildClick(event, "child", "singleDropDown", "isLogsRetentionDays");
                                            }
                                        }}
                                    >
                                        <p
                                        className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate" ${
                                            state["cloudwatch_logs_retention_in_days"] && props.pageMode === "Create" ? "text-white" : "placeholder"
                                        }`}
                                        >
                                        {state["cloudwatch_logs_retention_in_days"]
                                            ? propsClusterFilters.cloudwatch_logs_retention_in_days && propsClusterFilters.cloudwatch_logs_retention_in_days.filter((e) => e.value === state["cloudwatch_logs_retention_in_days"]).length
                                                ? propsClusterFilters.cloudwatch_logs_retention_in_days.filter((e) => e.value === state["cloudwatch_logs_retention_in_days"])[0].key
                                                : state["cloudwatch_logs_retention_in_days"]
                                            : "Select"}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.cloudwatch_logs_retention_in_days ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["isLogsRetentionDays"] &&
                                            props.pageMode === "Create" ? (
                                            <MultiSelectSection
                                                fields={["value", "key"]}
                                                options={
                                                propsClusterFilters && propsClusterFilters.cloudwatch_logs_retention_in_days
                                                    ? propsClusterFilters.cloudwatch_logs_retention_in_days.map(
                                                        (d) => ({ key: d, value: `${d}` })
                                                    ) : []
                                                }
                                                selectedValues={ state["cloudwatch_logs_retention_in_days"] ? state["cloudwatch_logs_retention_in_days"] : ""}
                                                callbackMultiSelect={(value) => {
                                                if (!value || typeof value === "string") {
                                                    handleConfigurationInputChange("cloudwatch_logs_retention_in_days", value, "integer");
                                                    setState((prevState) => ({ ...prevState, "cloudwatch_logs_retention_in_days": value }));
                                                } else {
                                                    value.preventDefault();
                                                    handleChildClick( value, "search", "singleDropDown", "");
                                                }
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        ) : null}
                                    </div>
                                    ) : (
                                    <p className="mb-0 mt-2 text-white text-base border border-lightGray rounded-5 px-2 py-1">
                                        {state.cloudwatch_logs_retention_in_days ? state.cloudwatch_logs_retention_in_days : ""}
                                    </p>
                                    )}
                                    </div>
                                    { propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.cloudwatch_logs_retention_in_days ? (
                                    <p className="mt-1 mb-0 text-info">
                                        { propsClusterFilters.description.storage_configuration.cloudwatch_logs_retention_in_days }
                                    </p>
                                    ) : null
                                }
                        </div>
                    : null}
                </div>

                <div className='flex mt-2 pt-2 border-darkGray-top'>
                    <div className='py-3 w-full'>
                        <p className='b-block mb-0'>Tags</p>
                        <div className='flex flex-wrap mb-1'>
                            <input
                                type='text'
                                placeholder='Enter Key'
                                className={`border border-lightGray rounded-md bg-transparent px-2 oy-1 text-white lg:w-1/3 md:w-1/2 w-full`}
                                // maxlength='128'
                                value={state.tag_key ? state.tag_key : ''}
                                onChange={e => 
                                    setState(prevState => ({ ...prevState, tag_key: e.target.value 
                                }))}
                            />
                            <input
                                type='text'
                                placeholder='Enter value'
                                className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white lg:w-1/3 md:w-1/2 w-full lg:ml-3 md:ml-3 lg:mt-0 md:mt-0 mt-2`}
                                // maxlength='128'
                                value={state.tag_value ? state.tag_value : ''}
                                onChange={e => 
                                    setState(prevState => ({ ...prevState, tag_value: e.target.value 
                                }))}
                            />
                            <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 lg:mt-2 md:mt-2 mt-0 cursor-pointer' onClick={() => addNewTagValue()}/>
                        </div>
                        {state.selected_tags && state.selected_tags.length ? 
                            <div className='flex flex-wrap'>
                                {state.selected_tags.filter(e => e.value !== 'All').map((tag, tIndex) => {
                                    return(
                                        <span key={'tag_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'tag_'+tIndex}>
                                            <div className='truncate'  style={{maxWidth: '250px'}}>
                                                {tag.key +' : '+tag.value}
                                            </div>
                                            <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={() => removeTagSection(tag)}/>
                                        </span>
                                    )
                                })}
                            </div>
                        : null}
                    </div>
                </div>
            </div>
        </div>

    )
}


export default ClusterSection