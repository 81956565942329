/*************************************************
 * Collider
 * @exports
 * @file Sidebar.js
 * @author Prakash // on 20/05/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import {Icon} from "@iconify/react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getUserMenu } from '../../actions/commonActionNew'
import { URL_PATH } from '../../config/urlPath';
const icons = [
	{label: "HPC", value: "mynaui:search"},
	{label: "Resources", value: "fluent:building-government-16-regular"}, 
	{label: "Cost", value: "healthicons:money-bag"},
	{label: "Governance", value: "bi:book"},
	{label: "Admin", value: "clarity:administrator-line"},
	{label: "ImageBuilder", value: "clarity:file-settings-line"}
]

const Sidebar = () => {
	const [state, setState] = useState({
		showSidebar: false,
	})
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const profileDetails = useSelector(state => state.filters.profileDetails);
	
	const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation();
	const navigate = useNavigate();
	const currentPath = location.pathname;
	useEffect(() => {
		dispatch(getUserMenu({}))
	}, [dispatch])
	
	useEffect(() => {
		if(userMenu && Object.entries(userMenu).length) {
			let subMenus = ["/admin/accounts", "/admin/settings", '/imagebuilder/audit']
			userMenu.menu && userMenu.menu.forEach(row => {
				if(row.submenulist && row.submenulist.length) {
					let menus = row.submenulist.map(e => e.link);
					subMenus = [...subMenus, ...menus]
				} else {
					subMenus.push(row.link)
				}
			})
			if(userMenu.additionalUrls) {
				subMenus = [...subMenus, ...userMenu.additionalUrls]
			}
			// additionalUrls
			
			if(!subMenus.includes(currentPath)) {
	            navigate(URL_PATH.PERMISSION_DENINED);
			}

			if(userMenu && Object.entries(userMenu).length && window.location.pathname === '/') {
				navigate(userMenu.defaultLandingPage && userMenu.defaultLandingPage.link ? userMenu.defaultLandingPage.link : URL_PATH.JOB_STATUS);
			}

			// dispatch(setCommonPropsDetails('userMenuAccess', subMenus))
		}
	}, [userMenu, currentPath, navigate])
	/**
	 * Renders sidebar of the screen
	 */
	return (
		<React.Fragment>
			{/* group h-full overflow-auto text-gray-400 bg-black fixed w-72 px-4 z-50 pb-5 */}
		<div className="group w-16 h-full overflow-auto text-gray-400 bg-black fixed transition-all duration-300 ease-in-out hover:w-72 pb-4 px-4 z-50">
			<Link className="flex justify-end mt-3">
				<Icon icon="material-symbols:menu" color="white" width="24" height="24" />
			</Link>
			<div>
				<div className="bg-orange w-7 h-7 rounded-full flex justify-center h items-center text-white mt-6 text-sm mx-auto">{profileDetails && profileDetails.userName ? profileDetails.userName[0].toUpperCase() : (profileDetails && profileDetails.firstName ? profileDetails.firstName[0].toUpperCase() : "")}</div>

				<p className="invisible group-hover:visible text-sm mt-3 text-white text-center">{profileDetails && profileDetails.userName ? profileDetails.userName : (profileDetails && profileDetails.firstName ? profileDetails.firstName : "")}</p>
			</div>
			<div className="flex flex-col mt-12 space-y-6">
				{userMenu && userMenu.menu && userMenu.menu.map((item, index) => {
					return (
						<React.Fragment key={"parent_child_index_"+index}>
							{item.submenulist && item.submenulist.length ?
								<React.Fragment>
								<div className={`group-hover:text-white flex flex-row gap-2 items-center cursor-pointer`}
									onClick={() => setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))}
								>
									<span className='flex'>
										<Icon icon={`${icons.filter(e => e.label === item.menu).length ? icons.filter(e => e.label === item.menu)[0].value : "mynaui:search"}`} color={'#FFFFFF'} width="18" height="18" />
									</span>
									
									<p className={`invisible group-hover:visible text-md text-${((item.submenulist && item.submenulist.length && item.submenulist.filter(e => e.link === currentPath).length) || item.link === currentPath) && (currentPath === item.link || item.submenulist) ? 'info' : 'white'}`}>{item.menu}</p>
								</div>
								{state['show_menu_'+index] ?
									<div className="flex justify-end flex-col m-0 menu">
										{item.submenulist.map((sub, i) => {
											return(
												<Link
													key={"child_index_"+index+"_"+i}
													className={`text-sm text-start mx-5 p-3 invisible group-hover:visible hover:bg-black4 whitespace-nowrap text-${(sub.link === currentPath) ? 'info' : 'white'}`}
													to={sub.link}
													// onClick={() => this.setState({ activeMenu: item.menu }) }
												>
													{sub.submenu}
												</Link>
											)
										})}
									</div>
								: null}
								</React.Fragment>				
							: 
								<div className={`group-hover:text-white flex flex-row gap-2 items-center cursor-pointer`}>
									<span className='flex'>
										<Icon icon={`${icons.filter(e => e.label === item.menu).length ? icons.filter(e => e.label === item.menu)[0].value : "mynaui:search"}`} color={'#FFFFFF'} width="18" height="18" />
									</span>
									<Link
										key={"parent_index_"+index}
										className={`text-sm text-start hover:bg-transparent whitespace-nowrap text-white invisible group-hover:visible `}
										to={(!item.submenulist || (item.submenulist && !item.submenulist.length)) ? item.link : ""}
										onClick={event => {
											if(item.submenulist && item.submenulist.length) {
												event.preventDefault()
												setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))
											} else {
												setState(prevState => ({ ...prevState, activeMenu: item.menu }))
											}
										}}
									>

										<span className={`nav-text ${window.location.pathname === item.link && (!item.submenulist || (item.submenulist && !item.submenulist.length)) ? 'text-info' : 'text-white'}`}>{item.menu}</span>
									</Link>
								</div>
							}
						</React.Fragment>
					)
				})}
			</div>
		</div>
		</React.Fragment>
	)
}

export default Sidebar
